@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'FrontPage/view/frontPage/components/travel-community/variables';
@import 'Results/view/results/components/tile/mixins/noLinkStyles';

.tile {
    position: relative;
    display: flex;
    height: 252px;
    grid-area: 1 / 1 / span 1 / span 2;
    border-radius: 10px;
    color: $silverStar;
    box-shadow:
        rgba($darkSideOfTheForce, 0) 0 0 0 0,
        rgba($darkSideOfTheForce, 0) 0 0 0 0,
        rgba($darkSideOfTheForce, 0.03) 6px 6px 6px 0,
        rgba($darkSideOfTheForce, 0.03) 3px 3px 5px 0,
        rgba($darkSideOfTheForce, 0.05) 5px 3px 6px 0;

    @include noLinkStyles;

    &:hover {
        color: $silverStar;
        box-shadow:
            rgba($darkSideOfTheForce, 0) 0 0 0 0,
            rgba($darkSideOfTheForce, 0) 0 0 0 0,
            rgba($darkSideOfTheForce, 0.26) 0 1px 3px 0;

        .header {
            color: $juna;
        }
    }

    @media screen and (max-width: $global-width) {
        height: 275px;

        .image {
            height: 275px;
        }

        .innerContent {
            padding-top: $global-spacing;
            padding-bottom: $global-spacing;
        }
    }
}

.imageContainer {
    flex: 1;
}

.image {
    height: 252px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.content {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.innerContent {
    padding: 0 $global-spacing-xlarge;
    display: flex;
    flex-direction: column;
    row-gap: $global-spacing;
}

.header {
    font-size: 18px;
    line-height: 1;
    font-weight: bold;
}

.subLine {
    font-size: 14px;
    line-height: 17px;
}

.chat {
    width: 100%;
    padding: 0 $global-spacing-smedium;
}

.message {
    display: flex;
    gap: $global-spacing;
    align-items: flex-end;

    + .message {
        margin-top: $global-spacing;
    }

    &:nth-of-type(2n + 1) {
        .messageContent {
            background-color: $frosteon;

            &:before {
                border-bottom-color: $frosteon;
                left: -1 * $global-spacing;
            }
        }
    }

    &:nth-of-type(2n) {
        flex-direction: row-reverse;

        .messageContent {
            background-color: $almostWhite;

            &:before {
                border-bottom-color: $almostWhite;
                right: -1 * $global-spacing;
            }
        }
    }
}

.user {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    object-fit: cover;
}

.messageContent {
    flex: 1;
    padding: $global-spacing-small;
    border-radius: 15px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        bottom: $global-spacing-xsmall;
        border: $global-spacing solid transparent;
    }
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    background: $sophieWhite;
    border-radius: 50%;
    border: 1px solid $marktMaedels;
}

.icon {
    display: inline-flex;
    font-size: 34px;
}

@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';
@import 'Icons/view/generated/icons';

// We deactivated the foundation-badge class and thus we can use this class name
$iconFontSize: 1.2em;

.badge {
    border-radius: $global-radius;
    font-size: 12px;
    line-height: 1;
    padding: 2px 5px;
    color: $sophieWhite;
    display: inline-flex;
    align-items: center;
    column-gap: 5px;
    position: relative;
    vertical-align: top;

    &__inverted {
        border-width: 1px;
        border-style: solid;
        padding: 1px 4px;
    }

    &__size_tight {
        padding: 0 4px;
        line-height: 16px;
        white-space: nowrap;
    }

    &__size_small {
        line-height: 1.5;
        padding: 2px $global-spacing-xsmall;

        &.badge__inverted {
            padding: 1px 3px;
        }
    }

    &__size_medium {
        line-height: 19px;
    }

    &__fontSize_large {
        font-size: 14px;
        line-height: 1.5em;
    }

    &__fontSize_small {
        font-size: 10px;
    }

    &__fontSize_xsmall {
        font-size: 8px;
        line-height: 1.8em;
    }

    &__asChip {
        border-radius: 1em;
        padding: 0 8px;
    }

    &__multiline {
        white-space: unset;
        line-height: unset;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    &__textAlign_right {
        text-align: right;
    }

    &__textAlign_left {
        text-align: left;
    }

    &__textAlign_center {
        text-align: center;
    }
}

.icon {
    display: inline-flex;
    font-size: $iconFontSize;

    &__xsmall {
        font-size: 10px;
    }

    &__small {
        font-size: 12px;
    }
}

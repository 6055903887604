@import 'Application/view/generated/colors';

.sponsoredHint {
    background-color: $ebbGrey;
    padding: 0 4px 4px;
    text-align: center;
}

.logo {
    height: 25px;
    margin-bottom: 4px;
}

.text {
    font-size: 10px;
    color: $silverStar;
}

@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/quadrat';

.tile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    border: 1px solid $marktMaedels;
    border-radius: 4px;
    column-gap: $global-spacing-smedium;

    &:hover {
        cursor: pointer;
        box-shadow: 0 0 5px 1px rgba($darkSideOfTheForce, 0.14);
    }

    &__biggerTiles {
        height: 140px;

        .name {
            font-size: 16px;
        }

        .count {
            color: $silverStar;
        }

        .imageContainer {
            width: 140px;
        }
    }
}

.imageContainer {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    overflow: hidden;
    width: 94px;
    min-height: 94px;
    height: 100%;
}

.image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    transition: all 250ms ease-in-out;

    @include quadrat(100%);
}

.contentContainer {
    flex: 1;
    padding: $global-spacing-smedium $global-spacing-smedium $global-spacing-smedium 0;
    overflow: hidden;
}

.name {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: $global-spacing-xsmall;
}

.count {
    font-size: 12px;
    line-height: 15px;
    color: $dustyGrey;
}

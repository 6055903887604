@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Results/view/results/components/tile/mixins/noLinkStyles';

.links {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: $global-spacing-smedium;
}

.linkColumn {
    flex: 20%;
    display: flex;
    flex-direction: column;
    row-gap: $global-spacing-smedium;
}

.link {
    @include noLinkStyles;

    flex: 20%;
    font-size: 14px;
    line-height: 18px;
    color: $silverStar;

    &:hover {
        color: $juna;
    }
}

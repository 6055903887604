@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/mixins/frontPage_mixins';

.headlineContainer {
    @include frontPageHeadline(false);

    margin-bottom: $frontpage-headlines-margin-bottom;
    text-align: center;

    &__shortHeadline {
        margin-bottom: $global-spacing;

        &:not(.headlineContainer__withBigHeadline) {
            .headline {
                font-size: 18px;
                font-weight: normal;
            }
        }
    }

    &__withBigHeadline {
        margin-bottom: $global-spacing-large;
    }
}

.headline {
    font-weight: bold;
    font-size: 23px;
    margin-bottom: 5px;
}

.subtitle {
    font-weight: normal;
    font-size: 14px;
}

.mobileCssSlider {
    height: 100%;

    &__dense-left {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }

    &__dense-center {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.sliderContainer {
    height: 100%;
    overflow-x: auto;
    display: flex;
    position: relative;

    // Hides scrollbar but div is still scrollable.
    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
}

.slide {
    flex: 0 0 calc(100vw);

    &__dense {
        flex: auto;
    }

    &:last-of-type {
        // Must be important to override inline style
        margin-right: 0 !important;
    }

    &__slidesAsFlexbox {
        display: flex;
    }
}

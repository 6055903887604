.elementRow {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
}

.elementColumn {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
}

@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.columns {
    display: flex;
    justify-content: space-around;
}

.column {
    display: flex;
}

.columnHeadline {
    font-size: 18px;
    margin-bottom: $global-spacing-xsmall;
    color: $sophieWhite;
}

.columnText {
    font-size: 14px;
    color: $sophieWhite;
}

.icon {
    font-size: 22px;
    margin-right: $global-spacing-small;
    color: $janJanJanJanJanJanJellow;
}

@import 'Application/view/framework/common/settings';
@import 'Icons/view/generated/icons';
@import 'Application/view/framework/common/mixins/mixins';

$componentClass: redirectErrorMessage;

.#{$componentClass} {
    $color: get-color(alert);

    display: flex;
    align-items: center;
    margin-top: $global-spacing;
    padding: 0.5 * $global-spacing;
    width: 100%;
    color: $color;
    border: 1px solid $color;
    border-left-width: 5px;
    background: $sophieWhite;

    &__icon {
        margin-right: 10px;

        &:before {
            @include iconFont;

            content: $exclamationIcon;
            font-size: 20px;
        }
    }

    &__message {
        font-weight: bold;
    }

    &--mobile {
        margin-top: 0;

        .#{$componentClass} {
            &__message {
                font-size: 14px;
            }
        }
    }
}

@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

@mixin inputOnYellowMask() {
    font-size: 14px;
    padding: 8px;
    border: 1px solid transparent;
    border-radius: $global-radius;
    transition: border-color 0.25s ease-out;

    &::placeholder {
        color: $plainGrey;
    }

    &:hover {
        border-color: $blueFire;
    }

    &:focus {
        border-color: $blueFire;
    }
}

@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/selectNone';
@import 'Application/view/framework/common/settings';
@import 'Icons/view/generated/icons';
@import 'Application/view/generated/zIndices';
@import 'Application/view/css-components/searchformVariables';

.productSwitch {
    position: relative;
    display: flex;
    max-width: $searchform-single-line-small-max-width;
    width: 100%;
    margin: 0 auto;

    &__theme-white {
        .tabContent {
            &,
            &__active {
                background: $sophieWhite;
                border: 1px solid $plainGrey;
                border-bottom: none;
            }
        }
    }

    &__theme-tabsAsBadges {
        max-width: $searchform-single-line-large-max-width;
        margin: 0 0 10px;

        .tabContent {
            background: $sophieWhite;
            border: 1px solid $marktMaedels;
            border-radius: 14px;
            line-height: 23px;
            height: 25px;
            width: unset;
            padding: 0 $global-spacing-small;
            margin-right: 10px;

            &__active {
                border-color: $scorpion;
            }

            &__touchDevice {
                &:focus,
                &:hover {
                    color: $tundora;
                }
            }
        }
    }

    &__theme-transparentBadges {
        max-width: $searchform-single-line-large-max-width;
        margin: 0 0 10px;

        .tabContent {
            background: rgba($sophieWhite, 0.15);
            border: 1px solid rgba($sophieWhite, 0.2);
            color: $sophieWhite;
            border-radius: 14px;
            line-height: 23px;
            height: 25px;
            width: unset;
            padding: 0 $global-spacing-small;
            margin-right: 10px;

            &__active {
                border-color: $sophieWhite;
            }

            &__mouseDevice {
                &:focus,
                &:hover {
                    color: $sophieWhite;
                    border-color: $sophieWhite;
                }
            }
        }
    }
}

.tab {
    position: relative;
    margin-left: 2px;

    &:first-child {
        margin-left: 0;
    }
}

.tabContent {
    height: 38px;
    background-color: $lightningYellow;
    color: $tundora;
    border-radius: 4px 4px 0 0;
    font-size: 14px;
    line-height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    @include selectNone;

    &__mouseDevice {
        &:focus,
        &:hover {
            color: $tundora;
            border-color: $scorpion;
        }
    }

    &__active {
        background-color: $janJanJanJanJanJanJellow;
        height: 38px;
        color: $tundora;
        text-decoration: none;
        margin-bottom: -1px;
    }

    &__alternative {
        width: 126px;
    }

    &__hotel {
        width: 112px;

        &.tabContent__active {
            font-weight: bold;
        }
    }

    &__homes {
        width: 144px;
    }
}

@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/tooltip/variables';

@mixin searchFormToolTipContainer {
    margin-top: 10px;
    padding: $global-spacing;
    background-color: $sophieWhite;
    box-shadow: $boxShadow;
    border-radius: $global-radius;
}

@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/search-form/components/mixins/inputOnYellowMask';
@import 'Application/view/framework/common/mixins/textOverflowEllipsis';

.singleLineRoomConfigurationInput {
    font-size: 16px;
    position: relative;
    outline: none;

    &__isC9SearchForm {
        .label {
            color: $silverStar;
        }
    }

    // Foundation uses 0.9..rem for labels, but externals have different values for 1rem.
    // With a scaling of 0.75 from FloatingLabel styles we get roughly 12px at the end.
    .label {
        font-size: 17px;
    }
}

.control {
    @include inputOnYellowMask;

    font-size: 16px;
    margin-bottom: 0;
    padding: 25px $global-spacing 5px 10px;
    background: $input-background;
    font-family: $input-font-family;
    display: block;
    position: relative;
    color: $input-color;
    height: 59px;
    white-space: nowrap;

    &__touchDevice {
        &:not(.control__active) {
            &:hover {
                border-color: $sophieWhite;
            }
        }
    }

    &__active {
        border-color: $blueFire;
    }

    // Line height is necessary, because the room configuration should be displayed at the same
    // height as the destination. The container has a height of 59px so the line height is 30px.
    line-height: 30px;

    &,
    & * {
        cursor: pointer;
    }

    &__hasError {
        border-color: get-color(alert) !important;
    }

    &__isExternal {
        color: $silverStar;
        font-size: 16px;
    }

    &__smallerInputField {
        @include textOverflowEllipsis;

        width: 300px;
    }
}

.input {
    width: 100%;
}

@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/textOverflowEllipsis';

.dropoutReminderOverlay {
    display: flex;
}

.container {
    position: relative;
    padding: $global-spacing-small;
    border-radius: $global-radius;
    background: rgba($effiEffiSteffi, 0.9);
    color: $sophieWhite;
    font-size: 14px;
}

.closeIcon {
    cursor: pointer;
    position: absolute;
    padding: $global-spacing-small;
    top: 0;
    left: 0;
    color: $marktMaedels;
}

.wrapper {
    padding-left: $global-spacing-medium;
}

.headline {
    font-weight: bold;
    margin-bottom: $global-spacing-small;
}

.content {
    display: flex;
    color: $sophieWhite;
    column-gap: $global-spacing-small;
}

.hotelImage {
    width: 60px;
    height: 60px;
    border-radius: $global-radius;
}

.text {
    color: $sophieWhite;
    width: 290px;
}

.hotelname {
    @include textOverflowEllipsis;

    font-weight: bold;
}

.arrow {
    font-size: 30px;
    display: flex;
    align-items: center;
    color: $marktMaedels;
    margin-left: auto;
}

@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.eyeCatcher {
    display: inline-block;
    text-align: left;
}

.tooltipContentContainer {
    & > {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 12px;
            font-weight: bold;
        }

        em,
        strong,
        b {
            font-weight: bold;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }
}

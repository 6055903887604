@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/mixins/icon_mixins';

@mixin topDestinationTile($gridGap, $borderRadius) {
    margin-right: $gridGap;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: box-shadow 500ms ease-out;
    border-radius: $borderRadius;

    &:hover {
        box-shadow:
            0 8px 10px 1px rgba($darkSideOfTheForce, 0.14),
            0 3px 14px 3px rgba($darkSideOfTheForce, 0.06),
            0 4px 10px 0 rgba($darkSideOfTheForce, 0.1);
    }

    &:last-of-type {
        margin-right: 0;
    }

    // This is the observable container and must have 100% height
    // Otherwise each tile will be in the viewport because of height 0
    & > div {
        height: 100%;
    }
}

@mixin topDestinationBigTile {
    height: 255px;
    flex: 0 1 33%;

    &__lessThanThree {
        flex: 0 1 50%;
    }
}

@mixin topDestinationSmallTile {
    height: 187px;
    flex: 0 1 25%;

    &__lessThanFour {
        flex: 0 1 33%;
    }
}

@mixin alternateDestination($gridGap) {
    @include legacyIconFont;

    flex: 1 1 25%;
    min-height: 60px;
    display: flex;
    align-items: center;
    margin-right: $gridGap;
    padding-left: 15px;
    padding-right: 45px;
    border-bottom: 1px solid $marktMaedels;
    color: $silverStar;
    cursor: pointer;
    transition: border-bottom-color 500ms;

    &:before {
        color: $plainGrey;
        content: $arrowContourRightIcon;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        font-size: 32px;
        transition: color 500ms;
    }

    &:hover {
        border-bottom: 1px solid $silverStar;
        color: $juna;

        &:before {
            color: $juna;
        }
    }

    &:last-of-type {
        margin-right: 0;
    }
}

@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.loginTeaserAdvantage {
    display: flex;
    align-items: center;
    font-size: 14px;

    + .loginTeaserAdvantage {
        margin-top: 4px;
    }
}

.icon {
    flex: 0 0 auto;
    color: $goldieMcGoldface;
    font-size: 16px;
    line-height: 1;
    margin-right: $global-spacing-small;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text {
    flex: 1 1 auto;
    line-height: 20px;
    color: $sophieWhite;
}

@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/generated/assets/assets';
@import 'Icons/view/generated/icons';
@include legacyIconClasses;

$componentClass: reasonsWhyBanderole;
$banderoleHeight: 340px;
$linearGradient: linear-gradient(rgba($silverStar, 0.4), rgba($silverStar, 0.4));

.reasonsWhyBanderole {
    min-height: $banderoleHeight;
    color: $sophieWhite;
    position: relative;

    &:before {
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        object-fit: cover;
        background-size: 1920px auto;
        background-repeat: no-repeat;
        background-position: center 0;

        // The variables in url() can't be used directly to work with Vite.
        // See: https://github.com/vitejs/vite/issues/7651.
        background-image: $linearGradient, url(#{$locationBerlinBerlinJpg});

        :global .webp & {
            background-image: $linearGradient, url(#{$locationBerlinBerlinWebp});
        }
    }

    &__es:before {
        background-image: $linearGradient, url(#{$locationMadridSkylineJpg});

        :global .webp & {
            background-image: $linearGradient, url(#{$locationMadridSkylineWebp});
        }
    }
}

.headline {
    position: relative;
    font-size: 23px;
    text-align: center;
    font-weight: bold;
    padding: 45px 0 80px;
}

.serviceReasonsContainer {
    display: flex;
    gap: $global-spacing;
    padding: 0 $global-spacing;
    font-size: 16px;
}

.serviceReasons {
    flex: 1;
    list-style: none;
}

.serviceReason {
    @include legacyIconFont;

    margin-bottom: 30px;
    line-height: 20px;

    &:before {
        margin-left: -20px;
        font-size: 10px;
        content: $checkIcon;
    }
}

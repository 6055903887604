@import 'Application/view/generated/colors';

.destinationSuggestionShowMoreLastSearchesToggle {
    display: block;
    width: 100%;

    // Enlarge button click area without moving its position to prevent click bug on Huawei device from occurring
    padding: 10px 0;
    margin: -10px 0;

    &__mobile {
        margin: 5px 0;
    }
}

@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.possibleUses {
    display: flex;
    justify-content: center;
    margin-bottom: $global-spacing;
    column-gap: $global-spacing-small;
}

.possibleUse {
    width: 100%;
    line-height: 1.2;
    text-align: center;
}

.icon {
    font-size: 25px;
}

.moreInfosLink {
    margin-top: $global-spacing;
    text-align: right;
}

.tooltipBadgeWrapper {
    // Override slick slider cursor pointer style
    &,
    & * {
        cursor: help !important;
    }
}

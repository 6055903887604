@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/mixins/frontPage_mixins';

.lastSearches {
    opacity: 0;

    // bottom is needed for smooth ux
    margin-bottom: -690px;

    /*
     * On translateY, more than 100% must be removed,
     * so margin-top is taken into account
     */
    transform: translateY(-130%);
    will-change: transform;

    &-appear-active {
        opacity: 1;
        margin-bottom: 0;
        will-change: contents;
        transform: translateY(0%) translateZ(0);
        transition:
            opacity ease 1.5s,
            margin-bottom linear 1s,
            transform linear 1s;
    }

    &-enter-done {
        opacity: 1;
        margin-bottom: 0;
        transform: translateY(0%);
    }

    &__disableSlideIn {
        margin-bottom: 0;
        transform: translateY(0%);
    }
}

.headline {
    @include frontPageHeadline;

    margin-bottom: $frontpage-headlines-margin-bottom;

    &__lessMargin {
        margin-bottom: $startpage-attack-headlines-margin-bottom;
    }
}

@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.hotelOverlayLayoutDesktop {
    display: flex;
    flex-direction: column;
    max-height: 90%;
    position: relative;
    max-width: $global-width;
    width: 100%;
    row-gap: 10px;

    &__smallWidth {
        max-width: 620px;
    }
}

.closeIconContainer {
    align-self: flex-end;
}

.contentContainer {
    background-color: $sophieWhite;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    font-size: 14px;
    line-height: 20px;
}

.scrollContainer {
    overflow-y: auto;
    max-height: 100%;
    padding: 20px;
}

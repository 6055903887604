@import 'Application/view/framework/common/settings';

.wrapper {
    &__withMarginTop {
        margin-top: $landingpage-top-level-element-spacing;
    }

    &__withMarginBottom {
        margin-bottom: $landingpage-top-level-element-spacing;
    }
}

@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.starsContainer {
    display: flex;
    align-items: center;
    width: fit-content;

    &__showAsBlock {
        display: block;
    }
}

.icon {
    color: $dustyGrey;
    font-size: 14px;
    margin-left: $global-spacing-xsmall;
    line-height: 0;
}

.trigger {
    display: flex;
    align-items: center;

    // Override slick slider cursor pointer style
    &,
    & * {
        cursor: help !important;
    }
}

.infoIcon {
    display: inline-flex;
    vertical-align: middle;
    color: $dustyGrey;
    line-height: 1;
    font-size: 12px;
    padding-left: $global-spacing-xsmall;

    &__smallerInfoIconFontSize {
        font-size: 10px;
    }
}

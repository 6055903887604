@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/mixins/c24Sprite';
@import 'Application/view/generated/assets/assets';
@import 'Application/view/generated/award-slider';

.searchFormEyeCatchers {
    position: absolute;
    display: flex;

    &__withSignetOnTop {
        .plectrum {
            top: 35px;
            right: 0;
        }

        .eyeCatcher {
            margin-top: 142px;
        }
    }
}

.eyeCatcher {
    display: inline-block;
    margin-left: 20px;

    // Fix for eye-catchers only hoverable at the top half
    z-index: 1;

    &:first-of-type {
        margin-left: 0;
    }
}

.plectrum {
    position: absolute;
    bottom: 0;
    right: -120px;

    &__es {
        bottom: 100px;
        right: -10px;
    }
}

.ekomi {
    @include c24Sprite('ekomiEs');

    transform-origin: right;
    display: inline-block;
    width: 74px;
    height: 74px;
    margin: 0 22px 4px 0;

    &:before {
        transform: scale(0.93);
    }
}

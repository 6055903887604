@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

$chipHeight: 34px;
$borderSize: 1px;

.travelToggleChip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $chipHeight;
    border: $borderSize solid $transparent;
    border-radius: 20px;
    padding: 0 15px;
    column-gap: 10px;

    &__mouseDevice {
        &:hover {
            cursor: pointer;
            background-color: rgba($sophieWhite, 0.1);
        }
    }

    &__isActive {
        color: $frosteon;
        border: $borderSize solid $sophieWhite;
        background-color: rgba($sophieWhite, 0.1);
        height: calc(#{$chipHeight} - #{($borderSize * 2)});
    }

    &__tablet {
        .label {
            line-height: unset;
        }
    }
}

.icon {
    display: flex;
    vertical-align: middle;

    &__withHomesIcon {
        margin-top: -2px;
    }

    @media (width <= 1400px) {
        display: none;
    }
}

.label {
    font-size: 14px;
    line-height: 14px;
    white-space: nowrap;
}

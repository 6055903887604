@import 'Application/view/generated/colors';
@import 'Icons/view/generated/icons';
@import 'Application/view/framework/common/mixins/topDestination_mixins';
@import 'Application/view/framework/common/scssHelper/cssGridHelpers';

.topAlternateDestinations {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 15px;
    font-size: 16px;
    position: relative;
}

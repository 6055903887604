@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.background {
    padding: $global-spacing;
    border-radius: $global-radius;
    min-height: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $global-spacing;
    border: 1px solid $marktMaedels;

    &__withMarginTop {
        margin-top: 60px;
    }

    &__withMarginBottom {
        margin-bottom: 60px;
    }

    &__smallLayout {
        min-height: 120px;

        .text {
            font-size: 16px;
        }
    }
}

.headline {
    font-size: 23px;
    font-weight: bold;
}

.text {
    font-size: 20px;
    margin-bottom: $global-spacing;
}

@import 'Application/view/framework/common/settings';

.travelCommunityWidget,
.topDestinationsContainer {
    margin-top: $landingpage-top-level-element-spacing;

    &__isStartPageAttackFeatureActive {
        margin-top: 0;
    }
}

.topDestinationsContainer {
    // Based on a header without subline & without alternate destinations
    min-height: 530px;
}

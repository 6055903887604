@import 'Application/view/generated/colors';
@import 'Application/view/generated/zIndices';
@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/mixins/outlineNone';

:global {
    /* stylelint-disable no-invalid-position-at-import-rule */
    @import 'swiper/swiper';
    /* stylelint-enable no-invalid-position-at-import-rule */
}

$arrowSize: 30px;

.arrowSliderSwiper {
    width: 100%;
    padding: 0 $arrowSize;
    position: relative;
    user-select: none;

    &__locked {
        padding: 0;
    }

    &__fullWidth {
        padding: 0;

        .arrowSliderArrow {
            z-index: zIndex(mobileOverlayElements);
        }
    }
}

.arrowSliderArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    cursor: pointer;
    top: 50%;
    font-size: $arrowSize;
    color: $marktMaedels;

    &__circle {
        @include outlineNone;

        color: $scorpion;
        font-size: 32px;
        border-radius: 1000px;
        box-shadow: 0 2px 8px 0 rgba($darkSideOfTheForce, 0.16);
        background: $white;
        display: flex;
        border: 1px solid rgba($darkSideOfTheForce, 0.08);

        &.arrowSliderArrow:hover {
            color: $scorpion;
        }

        &.arrowSliderArrow__disabled,
        &.arrowSliderArrow__disabled:hover {
            color: $marktMaedels;
        }

        &.arrowSliderArrow {
            &__left {
                transform: translateY(-50%) translateX(-50%);
            }

            &__right {
                transform: translateY(-50%) translateX(50%);
            }
        }
    }

    &:hover {
        color: $blueFire;
    }

    &__left {
        left: 0;

        // Aligns the arrow with the center and the left edge of the parent container
        transform: translateY(-50%) translateX(-10px);
    }

    &__right {
        right: 0;

        // Aligns the arrow with the center and the right edge of the parent container
        transform: translateY(-50%) translateX(10px);
    }

    &__hideArrowsAtSliderEnd {
        &.arrowSliderArrow__disabled,
        &.arrowSliderArrow__disabled:hover {
            display: none;
        }
    }

    &__locked {
        display: none;
    }
}

.pagination {
    position: absolute;
    right: 5px;
    bottom: 5px;
    background-color: rgba($darkSideOfTheForce, 0.5);
    color: $white;
    border-radius: 3px;
    padding: $global-spacing-small;
    z-index: zIndex(mobileOverlayElements);
}

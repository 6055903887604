@import 'Application/view/framework/common/settings';

.recommendationsContainer {
    display: flex;
    flex-direction: column;
    gap: $landingpage-top-level-element-spacing;
}

.placeholder {
    width: 100%;
    height: 300px;
}

@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/search-form/components/mixins/inputOnYellowMask';

.singleLineDateRange {
    @include inputOnYellowMask;

    &__touchDevice {
        &:not(.singleLineDateRange__focused) {
            &:hover {
                border-color: transparent;
            }
        }
    }

    font-size: 16px;
    cursor: pointer;
    position: relative;
    background-color: $sophieWhite;
    padding: 25px $global-spacing 5px 10px;
    height: 59px;

    // Line height is necessary, because the date should be displayed at the same height
    // as the destination. The container has a height of 59px so the line height is 30px.
    line-height: 30px;

    &__focused {
        border-color: $blueFire;
    }

    &__highlighted {
        border: 1px solid $blueFire;
        box-shadow: 0 0 0 1px $blueFire;
    }

    &__hasInvalidValue {
        border-color: get-color(alert);
    }

    &__isC9SearchForm {
        .label {
            color: $silverStar;
        }
    }

    // Foundation uses 0.9..rem for labels, but externals have different values for 1rem.
    // With a scaling of 0.75 from FloatingLabel styles we get roughly 12px at the end.
    .label {
        font-size: 17px;
    }

    &__isExternal {
        .date {
            font-size: 16px;
        }
    }
}

.date {
    white-space: nowrap;

    &__placeholder {
        color: $dustyGrey;
    }
}

.departurePlaceholder {
    color: $dustyGrey;
}

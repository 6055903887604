@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.advantagesDesktop {
    margin-top: $landingpage-top-level-element-spacing;

    &__withoutTopMargin {
        margin-top: 0;
    }

    &__withGreyBackground {
        margin-top: $global-spacing-xlarge;
        background-color: $almostWhite;

        .headline {
            padding-top: $global-spacing-large;
        }

        .advantageItems {
            padding-bottom: $global-spacing-large;
        }

        &.advantagesDesktop__withBottomMargin {
            margin-bottom: $frontpage-headlines-margin-bottom;
        }
    }

    &__withBottomMargin {
        margin-bottom: $global-spacing;
    }
}

.headline {
    font-size: 23px;
    font-weight: bold;
    margin-bottom: $frontpage-headlines-margin-bottom;
    text-align: center;

    &__lessMargin {
        margin-bottom: $startpage-attack-headlines-margin-bottom;
    }
}

.advantageItems {
    display: flex;
    justify-content: center;
    column-gap: $global-spacing;
}

@import 'Application/view/framework/common/settings';

.content {
    padding: 15px;
}

.loyaltyBadge {
    display: flex;
    align-items: center;
    gap: $global-spacing-small;
    font-size: 14px;
}

.tooltipBadgeWrapper {
    cursor: help;
}

@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.priceStructureItem {
    font-size: var(--price-overview-font-size, 16px);
    display: flex;
    justify-content: space-between;
    column-gap: $global-spacing-small;
    line-height: 18px;

    &__isForeignPrice {
        color: $dustyGrey;
        font-size: var(--price-overview-foreign-font-size, 14px);
    }

    &__isDiscount {
        color: $chatbotBaumGreen;
    }

    &__isBookingCanceled {
        color: $dustyGrey;
    }
}

.totalPriceLabel {
    font-size: var(--price-overview-font-size, 16px);
    font-weight: bold;
}

.totalPrice {
    font-weight: bold;
    font-size: var(--price-overview-font-size, 16px);
}

@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.roomSetupInput {
    &__renderInOverlay {
        border: 1px solid $almostWhite;

        .headline {
            background-color: $almostWhite;
            padding: 0 $global-spacing;
        }

        .content {
            padding: 0 $global-spacing $global-spacing;
            display: flex;
        }

        .counts {
            width: 40%;
        }

        .childInputs {
            width: 40%;
            margin: 0 0 0 $global-spacing;
        }
    }
}

.headline {
    display: flex;
    justify-content: space-between;
    margin-bottom: $global-spacing-smedium;
    font-size: 16px;
    font-weight: bold;
    color: $silverStar;
}

.removeIcon {
    color: $plainGrey;
    font-size: 22px;
    line-height: 1;
    cursor: pointer;

    &:hover {
        color: $silverStar;
    }
}

.counts {
    display: flex;
    flex-flow: column;
    row-gap: $global-spacing;
}

.count {
    display: flex;
    justify-content: space-between;
}

.countLabel {
    display: flex;
    align-items: center;
}

.childInputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: $global-spacing;
    gap: $global-spacing;
}

.childInput {
    width: calc(50% - #{$global-spacing-small});
}

.childHint {
    font-size: 12px;
    width: 100%;
}

@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/zIndices';
@import 'Application/view/components/newsbanner-hint/mixins/orangeLinksOnHover';

.newsBannerHintWrapper {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: -20px;
    pointer-events: none;
    z-index: zIndex('contentElements');
    overflow: hidden;
}

.newsBannerHint {
    position: relative;
    box-shadow: unset;
    pointer-events: all;
    background: rgba($marktMaedels, 0.2);
}

.content {
    display: flex;
    margin: auto;
    padding: $global-spacing;
    align-items: center;

    &__es {
        max-width: 1280px;
    }
}

.headline {
    font-size: 18px;
}

.text {
    font-size: 14px;

    // The API gives the HTML content, which includes an <a> tag.
    @include orangeLinksOnHover;
}

.icon {
    padding-right: $global-spacing-smedium;
    flex: 0 0 auto;
    line-height: 1;
    font-size: 24px;
}

.closeWrapper {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-left: $global-spacing;
}

.close {
    cursor: pointer;
}

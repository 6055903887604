@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Icons/view/generated/icons';

$loginIconSize: 30px;

.loginTeaser {
    background-color: $buckIsBluetiful;

    &__withBottomMargin {
        margin-bottom: $landingpage-top-level-element-spacing;
    }
}

.wrapper {
    position: relative;
    max-width: $global-width;

    // right $global-spacing-medium + padding from searchform
    padding: 20px $global-spacing-medium + 15px 20px $global-spacing-medium;
    margin: 0 auto;
}

.headline {
    display: flex;
    align-items: center;
    margin-bottom: $global-spacing;
    position: relative;
}

.title {
    font-size: 24px;
    color: $sophieWhite;
}

.icon {
    display: flex;
    font-size: $loginIconSize;
    color: $sophieWhite;
    margin-right: $global-spacing-medium;
}

.content {
    display: flex;
    align-items: flex-end;
}

.advantages {
    flex: 1 1 auto;
    line-height: 21px;
}

.checkIcon {
    // This overrides the default styles of the advantage check icons
    flex: 0 0 $loginIconSize !important;
    margin-right: $global-spacing-medium !important;
    color: $sophieWhite !important;
}

.buttonContainer {
    flex: 0 0 256px;
    height: 60px;
}

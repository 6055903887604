@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/selectNone';
@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/zIndices';

.productTabs {
    position: relative;
    display: flex;

    &__tab {
        height: 42px;
        background-color: $lightningYellow;
        border-radius: 4px 4px 0 0;
        box-shadow: 0 2px 8px 0 rgba($darkSideOfTheForce, 0.15);
        color: $tundora;
        margin-right: 2px;
        border: 1px solid transparent;
        border-bottom: none;
        font-size: 14px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;

        @include selectNone;

        &--active,
        &:hover {
            background-color: $janJanJanJanJanJanJellow;
            color: $tundora;
            text-decoration: none;
            height: 43px;
            margin-bottom: -1px;
            position: relative;
            z-index: zIndex(contentElements);

            &:after {
                background-color: $janJanJanJanJanJanJellow;
                pointer-events: none;
                height: 10px;
                content: '';
                position: absolute;
                bottom: -10px;
                left: -10px;
                right: -10px;
            }
        }

        &--packageVacation {
            width: 157px;
        }

        &--rentalCar {
            width: 160px;
        }

        &--flight {
            width: 120px;
        }

        &--hotel {
            width: 120px;
        }

        &--homes {
            width: 160px;
        }

        &:first-child {
            &:after {
                left: 0;
            }
        }
    }
}

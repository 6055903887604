@import 'Application/view/framework/common/settings';

.slider {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    width: 100%;

    &__visible {
        opacity: 1;
        visibility: visible;
    }
}

.leftArrow {
    // Overwrite style from ArrowSlider
    left: $global-spacing-smedium !important;
}

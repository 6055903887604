@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.travelToggle {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    padding: 0 50px;
    background: $lapisBlue;
    font-size: 14px;
    color: $sophieWhite;
    position: sticky;
    top: 0;
    height: 42px;

    @media (width <= 1280px) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (width <= 1024px) {
        // To fix the white line between wireframe and travel toggle.
        margin-top: -1px;
    }

    @media (width <= 1060px) {
        // To prevent hiding chips below scrollbar.
        height: 60px;
    }
}

.chipContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
}

.linkContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 50px;
}

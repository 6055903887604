@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/mixins';

.hotelRatingIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 36px;
    height: 35px;
    margin-right: $global-spacing-smedium;
    border-radius: 5px 5px 0;
    font-size: 16px;
    font-weight: normal;
    color: $sophieWhite;
    background-color: $buckIsBluetiful;
    flex: 0 0 auto;

    &__hovered {
        background-color: darken($buckIsBluetiful, 5%);
    }

    &__huge {
        width: 48px;
        height: 47px;
        font-size: 18px;
    }

    &__big {
        width: 32px;
        height: 32px;
        font-size: 16px;
        margin-right: 6px;
    }

    &__medium {
        width: 30px;
        height: 30px;
        font-size: 12px;
        margin-right: 6px;
    }

    &__small {
        width: 26px;
        height: 26px;
        font-size: 12px;
        margin-right: 6px;
    }

    &__tiny {
        width: 26px;
        height: 22px;
        font-size: 12px;
        margin-right: 6px;
    }

    &__rightToLeft {
        margin-right: 0;
        margin-left: 10px;
    }

    &__inverted {
        color: $silverStar;
        border: 1px solid $silverStar;
        background-color: $sophieWhite;
    }
}

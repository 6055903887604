@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/selectNone';

.hotelHomesProductSwitch {
    display: flex;
}

.tab {
    @include selectNone;

    background-color: $lightningYellow;
    border-radius: 4px 4px 0 0;
    border: 1px solid transparent;
    border-bottom: none;
    box-shadow: 0 2px 8px 0 rgba($darkSideOfTheForce, 0.15);
    color: $tundora;
    cursor: pointer;
    flex: 0 1 auto;
    font-size: 14px;
    padding: 11px 20px 10px;
    position: relative;
    text-align: center;
    transition: background-color 0.3s;
    margin-bottom: -1px;

    & ~ .tab {
        margin-left: 2px;
    }

    &__hotel {
        min-width: 120px;
    }

    &__homes {
        min-width: 160px;
    }

    &:hover,
    &__active {
        background-color: $janJanJanJanJanJanJellow;
        position: relative;
        z-index: zIndex(contentElements);

        // This hacks removes (overlaps) the bottom box-shadow
        // Straight copied from module/Application/view/components/searchBox/ProductSwitch.module.scss
        &:after {
            background-color: $janJanJanJanJanJanJellow;
            pointer-events: none;
            height: 10px;
            content: '';
            position: absolute;
            bottom: -10px;
            left: -10px;
            right: -10px;
        }
    }

    &:first-child {
        &:after {
            left: 0;
        }
    }
}

@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'FrontPage/view/frontPage/components/travel-community/variables';

.tile {
    height: $travelCommunityTileImageHeight;
    border: 1px solid $marktMaedels;
    border-radius: 4px;
    padding: $global-spacing;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    font-size: 13px;
}

.header {
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
}

.subLine {
    line-height: 15px;
}

.chat {
    width: 100%;
}

.message {
    display: flex;
    gap: $global-spacing;
    align-items: flex-end;

    + .message {
        margin-top: $global-spacing;
    }

    &:nth-of-type(2n + 1) {
        .messageContent {
            background-color: $frosteon;

            &:before {
                border-bottom-color: $frosteon;
                left: -1 * $global-spacing;
            }
        }
    }

    &:nth-of-type(2n) {
        flex-direction: row-reverse;

        .messageContent {
            background-color: $almostWhite;

            &:before {
                border-bottom-color: $almostWhite;
                right: -1 * $global-spacing;
            }
        }
    }
}

.user {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    object-fit: cover;
}

.messageContent {
    flex: 1;
    padding: $global-spacing-small;
    border-radius: 15px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        bottom: $global-spacing-xsmall;
        border: $global-spacing solid transparent;
    }
}

.link {
    display: inline-block;
    margin-top: $global-spacing;
}

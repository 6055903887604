@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/zIndices';

:global {
    // To let sticky work the element must be placed in a container which covers the whole page height.
    #dropOutReminderContainer {
        position: sticky;
        top: 0;
        z-index: zIndex(overlays);
    }
}

.dropoutReminderOverlayStickyWrapper {
    position: relative;
}

.overlay {
    position: absolute;
    top: $global-spacing;
    right: $global-spacing;
}

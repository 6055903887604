@import 'Application/view/generated/colors';

.pointsIcon {
    color: $silverStar;
    position: relative;
    display: flex;

    &__isDisabled {
        color: $plainGrey;
    }
}

.svg {
    display: block;
    position: relative;
    height: 1em;
    fill: currentColor;
    overflow: visible;
}

.svgWrapper {
    display: inline-block;
    position: relative;
}

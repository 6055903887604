@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.tile {
    overflow: hidden;
    cursor: pointer;

    &__mobile {
        .count {
            font-size: 14px;
            color: $dustyGrey;
        }
    }
}

.image {
    width: 100%;
    max-height: 140px;
    border-radius: 5px;
    object-fit: cover;
}

.content {
    padding-top: $global-spacing-smedium;
}

.name {
    font-size: 16px;
}

.count {
    font-size: 12px;
}

@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.destinationSuggestionHeadline {
    font-size: 16px;
    color: $silverStar;
    line-height: 1.2;

    &__mobile {
        margin-bottom: $global-spacing-small;
        font-size: 20px;
    }

    &__withTopSpacing {
        margin-top: $global-spacing;
    }
}

@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.loginTeaserAdvantageCard {
    display: block;
    height: 150px;
    border: 1px solid $goldieMcGoldface;
    border-radius: 7px;

    &__mobile {
        height: 130px;
        width: 250px;

        &.loginTeaserAdvantageCard__isFirstCard {
            .headline {
                font-size: 16px;
            }
        }

        .wrapper {
            padding-bottom: 0;
        }

        .headlineContainer {
            height: 42px;
        }

        .headline {
            font-size: 14px;
        }
    }

    &:hover {
        box-shadow: 0 0 5px 1px rgba($darkSideOfTheForce, 0.14);
    }

    &__isLocked {
        border: 1px solid $dustyGrey;

        .headline {
            color: $scorpion;
        }

        .icon {
            color: $dustyGrey;
        }

        .text {
            color: $doveGrey;
        }
    }

    &__isFirstCard {
        background: $goldieMcGoldface;

        .headline {
            font-size: 23px;
            font-weight: normal;
            color: $sophieWhite;
        }

        .text {
            color: $sophieWhite;
        }
    }
}

.wrapper {
    display: block;
    padding: 16px;
}

.headlineContainer {
    display: flex;
    justify-content: space-between;
    gap: $global-spacing-small;
    height: 60px;
}

.headline {
    color: $silverStar;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.3;
}

.icon {
    color: $goldieMcGoldface;
    display: flex;
    align-items: flex-start;
}

.text {
    color: $silverStar;
    font-size: 14px;
}

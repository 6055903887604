$componentClass: bestPriceEyeCatcher;

.bestPriceEyeCatcher {
    &__standalone {
        position: absolute;
        top: 30px;
        right: 24px;
    }

    &__homeTeaser {
        top: 52px;
    }
}

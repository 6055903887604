@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/textOverflowEllipsis';
@import 'Application/view/homes/css-components/variables';

.topDestinationTile {
    box-shadow: none;

    &__small {
        .locationName {
            font-size: 16px;
        }
    }
}

.image {
    border-radius: $borderRadius;
    object-fit: cover;
    background-color: $almostWhite;
    height: 100%;
    width: 100%;
    transition: transform 250ms ease-in-out;
}

.content {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

.header {
    position: absolute;
    padding: 15px;
    font-size: 12px;
    color: $sophieWhite;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-image: linear-gradient(rgba($darkSideOfTheForce, 0.5), transparent);
}

.locationName {
    @include textOverflowEllipsis;

    font-size: 19px;
}

@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/mixins';

.singleLineDestinationSuggestionInput {
    position: relative;
    padding: 0;
    margin: 0;
    font-size: 16px;
}

.suggestionList {
    margin: 0;
    list-style: none;
}

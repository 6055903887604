@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.teaser {
    width: 100%;
    background: transparent linear-gradient(180deg, $buckIsBluetiful, $darkSideOfTheForce);
    color: $sophieWhite;
    padding: 26px 0 42px;
    border-radius: $global-radius;
    margin-bottom: $landingpage-teaser-margin - $landingpage-top-level-element-spacing;

    &__withBiggerMarginTop {
        margin-top: $landingpage-teaser-margin;
    }

    &__withBiggerMarginBottom {
        margin-bottom: $landingpage-teaser-margin;
    }
}

.headline {
    font-size: 24px;
    margin-bottom: $global-spacing-large;
    text-align: center;
}

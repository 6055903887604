@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/mixins';

$dayBoxSize: 34px;
$dayPadding: 5px;

.iconLeft {
    flex: 0 0 $dayBoxSize;
    display: flex;
    align-self: center;
    cursor: pointer;
    color: $grey;
    margin-left: auto;
    margin-right: $dayPadding;

    &:hover {
        color: $blueFire;
    }
}

.navButtonIcon {
    display: flex;
    margin: auto;
    position: relative;
    font-size: 16px;
}

@import 'Application/view/generated/colors';

.animatedPlaceholder {
    position: relative;
    background: var(--background-color);
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        inset: 0;
        background-image: linear-gradient(to right, transparent 40%, var(--animation-color), transparent 60%);
        background-position: center;
        background-size: 300%;
        will-change: transform;
        animation: loadingAnimation 1.5s infinite;
    }

    --background-color: #{$gallery};
    --animation-color: #{$marktMaedels};

    &__blueIndicator {
        --background-color: #{$gallery};
        --animation-color: #{$blueFire};
    }

    &__lightBlueBackground {
        --background-color: #{$marktMaedels};
        --animation-color: #{$plainGrey};
    }

    &__hellasGreyBackground {
        --background-color: #{$hellasGrey};
        --animation-color: #{$marktMaedels};
    }

    &__frosteonBackground {
        --background-color: #{$frosteon};
        --animation-color: #{$marktMaedels};
    }

    &__pummeluffPinkBackground {
        --background-color: #{$pummeluffPink};
        --animation-color: #{$marktMaedels};
    }
}

@keyframes loadingAnimation {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

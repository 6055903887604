@import 'Application/view/framework/common/settings';

.header {
    font-size: 23px;
    font-weight: bold;
    text-align: center;
}

.text {
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 53px;
    text-align: center;

    &__lessMargin {
        padding-bottom: $startpage-attack-headlines-margin-bottom;
    }
}

.tiles {
    display: flex;
    gap: $global-spacing;
}

.tile {
    flex: 1;
}

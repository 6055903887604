@import 'Application/view/framework/common/settings';

.locationContainer {
    &__biggerTiles {
        .topics {
            margin-bottom: $global-spacing-medium;
        }

        .locations {
            height: 140px; // Same height as tile
        }
    }
}

.headline {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: $global-spacing-small;
}

.topics {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: $global-spacing;
}

.locations {
    position: relative;
    height: 96px; // Same height as tile

    &__withMarginBottom {
        margin-bottom: $global-spacing-medium;
    }
}

$searchform-border-radius: 5px;
$searchform-input-height: 52px;
$searchform-input-font-size: 18px;
$searchform-input-outerSpacing-vertical: 5px;
$searchform-input-spacing-horizontal: 8px;
$searchform-input-innerSpacing-top: 3px;
$searchform-input-innerSpacing-bottom: 4px;
$searchform-input-margin-bottom: 8px;
$searchform-input-borderWidth: 1px;
$searchform-single-line-small-max-width: 1100px;
$searchform-single-line-large-max-width: 1280px;

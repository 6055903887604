.head {
    width: 100%;
    display: flex;
    position: relative;
}

.weekday {
    flex: 1 0 auto;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
}

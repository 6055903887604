@import 'Application/view/framework/common/mixins/topDestination_mixins';
@import 'Application/view/homes/css-components/variables';

$gridGap: 15px;

.topDestinations {
    margin-left: auto;
    margin-right: auto;

    &__withMarginBottom {
        margin-bottom: 5px;
    }
}

.header {
    padding-bottom: 37px;

    &__lessMargin {
        padding-bottom: $startpage-attack-headlines-margin-bottom;

        .headline {
            margin-bottom: 0; // Overrides the default h2 margin
        }
    }
}

.headline {
    font-weight: bold;
    font-size: 23px;
    text-align: center;
}

.subline {
    font-size: 14px;
    text-align: center;
}

.container {
    position: relative;
    width: 100%;
    display: flex;
}

.containerSpace {
    margin-bottom: $gridGap;
}

.tile {
    @include topDestinationTile($gridGap, $borderRadius);
}

.bigTile {
    @include topDestinationBigTile;
}

.smallTile {
    @include topDestinationSmallTile;
}

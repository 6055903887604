// We override the slick slider default behaviour if needed (see below)
$slick-font-path: 'slick-carousel/slick/fonts/';
$slick-loader-path: 'slick-carousel/slick/';

/* stylelint-disable no-invalid-position-at-import-rule */
@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/generated/zIndices';
@import 'Icons/view/generated/icons';
@import 'Application/view/framework/common/mixins/mixins';
/* stylelint-enable no-invalid-position-at-import-rule */

// a surrounding container to hide parts of sliding slides that should not be visible
// minus margin is evened out in arrowSlider and global(.slick-slider)
.arrowSliderContainer {
    position: relative;
    margin: 0 -#{$global-spacing-small};
}

$arrowHeight: 47px !default;
$arrowWidth: 30px !default;

@mixin sliderArrow($direction) {
    $translateX: -50%;

    @if $direction == 'right' {
        $translateX: 50%;
    }

    position: absolute;
    top: 50%;
    color: $scorpion;
    z-index: zIndex(partialElements);
    #{$direction}: $global-spacing-small;
    font-size: 32px;
    border-radius: 1000px;
    box-shadow: 0 2px 8px 0 rgba($darkSideOfTheForce, 0.16);
    background: $sophieWhite;
    display: flex;
    transition:
        transform 100ms,
        color 100ms;
    border: 1px solid rgba($darkSideOfTheForce, 0.08);
    transform: translate($translateX, -50%);

    &__mouseDevice {
        &:hover {
            color: $scorpion;
            cursor: pointer;
            transform: translate($translateX, -50%) scale(1.1);
        }
    }

    &__withoutZIndex {
        z-index: 0;
    }

    &__noCircleSmall {
        border: unset;
        border-radius: unset;
        box-shadow: unset;
        background: unset;
        #{$direction}: $global-spacing-small;
    }

    &__noCircleLarge {
        border: unset;
        border-radius: unset;
        box-shadow: unset;
        background: unset;
        font-size: $arrowHeight;
        color: $marktMaedels;
        #{$direction}: -$global-spacing-small;

        &:hover {
            color: $blueFire;
            transform: translate($translateX, -50%);
        }
    }
}

.arrowSlider {
    padding: 0 $arrowWidth;
    overflow: hidden;

    // margin to even out minus margin in arrowSliderContainer (see also global(.slick-slider))
    margin: 0 $global-spacing-xsmall;

    :global {
        /* stylelint-disable no-invalid-position-at-import-rule */
        @import 'slick-carousel/slick/slick';
        @import 'slick-carousel/slick/slick-theme';
        /* stylelint-enable no-invalid-position-at-import-rule */
    }

    &__noPadding {
        padding: 0;
    }

    &__asFlexBox {
        :global(.slick-track) {
            display: flex;
        }
    }

    &__noScrollingPossible {
        :global(.slick-track) {
            // Overriding a bug from react-slick library. If slidesToShow are 4 but you have only 3 items,
            // they will always be pushed to the right by a transform: translate3d(slideWidth, 0, 0).
            transform: unset !important;
        }
    }

    &__align {
        &-left {
            :global(.slick-track) {
                margin-left: 0;
            }
        }

        &-right {
            :global(.slick-track) {
                margin-right: 0;
            }
        }
    }

    /**
    * Overrides slick slider default behaviour
    */
    :global(.slick-slider) {
        // position is set in surrounding arrowSliderContainer
        position: unset;

        // margin to even out minus margin in arrowSliderContainer (see also arrowSlider)
        margin: 0 $global-spacing-xsmall;
    }

    :global(.slick-slide) {
        display: block;

        &:focus {
            outline: none;
        }

        & > div {
            height: 100%;

            &:focus {
                outline: none;
            }
        }

        :global(.slick-slide) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__equalSlidesHeight {
        :global(.slick-track) {
            display: flex;
            align-items: stretch;
            justify-content: flex-end;
        }

        :global(.slick-slide) {
            height: auto;
            padding-left: $global-spacing-smedium;
            padding-right: $global-spacing-smedium;
        }

        :global(.slick-list) {
            margin-left: -$global-spacing-smedium;
            margin-right: -$global-spacing-smedium;
        }
    }

    &__withArrowButtons {
        padding: 0;

        &.arrowSlider__equalSlidesHeight {
            :global(.slick-slide) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &.arrowSlider {
            :global(.slick-slide) {
                padding-left: $global-spacing-smedium;
                padding-right: $global-spacing-smedium;
            }

            :global(.slick-list) {
                margin-left: -$global-spacing-smedium;
                margin-right: -$global-spacing-smedium;
            }
        }

        &.arrowSlider__withPadding {
            padding: 0 $global-spacing-large;
        }

        &.arrowSlider__withSmallerTileSpacing {
            :global(.slick-slide) {
                padding-left: $global-spacing-small;
                padding-right: $global-spacing-small;
            }

            :global(.slick-list) {
                margin-left: -$global-spacing-small;
                margin-right: -$global-spacing-small;
            }
        }

        &.arrowSlider__smallArrows {
            :global(.slick-slide) {
                padding-right: 0;
            }

            :global(.slick-list) {
                margin-right: 0;
            }
        }
    }

    &__noCircleSmall {
        padding: 0 $global-spacing;
    }
}

.arrowLeft {
    @include sliderArrow(left);
}

.arrowRight {
    @include sliderArrow(right);
}

@use 'sass:list';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/mixins/frontPage_mixins';
@import 'Application/view/framework/common/mixins/textOverflowEllipsis';

.content {
    display: grid;
    grid-template: list.slash(repeat(2, 1fr), repeat(2, 1fr));
    grid-gap: $global-spacing;
}

.article {
    position: relative;
    background-color: $sophieWhite;
    border: 1px solid $marktMaedels;
    border-radius: $global-radius;
    display: flex;
    height: 189px;

    &:hover {
        background-color: $lightGrey;
    }
}

.articleImage {
    width: 167px;
    border-radius: 2px;
    background-position: center;
    background-size: cover;
    flex: 1 0 auto;
}

.titleGradient {
    height: 40px;
    width: 100%;
    position: absolute;
    border-radius: 0 0 $global-radius $global-radius;
    bottom: 0;
    left: 0;
    background: linear-gradient(transparent, $silverStar);
}

.title {
    @include textOverflowEllipsis;

    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 16px;
    padding: 0 0 7px 10px;
    width: 95%;
    color: $sophieWhite;
}

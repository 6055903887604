@import 'Application/view/generated/colors';

.travelCenterLink {
    color: $sophieWhite;
    line-height: 14px;
    white-space: nowrap;

    &:hover {
        color: $sophieWhite;
    }

    @media only screen and (width <= 1880px) {
        display: none;
    }

    &__tablet {
        line-height: unset;
    }
}

@import 'Application/view/framework/common/settings';

.header {
    font-size: 23px;
    font-weight: bold;
    text-align: center;
    margin-bottom: $global-spacing-large;
}

.tiles {
    display: grid;
    grid-gap: $global-spacing;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.priceStructureOverview {
    display: flex;
    flex-direction: column;
    row-gap: $global-spacing-small;
}

.priceStructureItems {
    display: flex;
    flex-direction: column;
    row-gap: $global-spacing-small;

    &:empty {
        display: none;
    }
}

.showDetailsLink {
    font-size: var(--price-overview-font-size, 16px);
}

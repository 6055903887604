@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.addListButton {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.plusButton {
    color: $sophieWhite;
    background-color: $buckIsBluetiful;
    border-radius: 10px;
    width: 60px;
    height: 60px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addListButtonText {
    font-size: 16px;
    font-weight: bold;
    padding: 0 $global-spacing-small;
}

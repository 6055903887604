@import 'Application/view/generated/colors';

.starsPreciseSvg {
    display: block;

    &__inline {
        display: inline-block;
    }
}

.star {
    fill: $minasStern;
}

.greyStar {
    fill: $marktMaedels;
}

@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/css-components/searchformVariables';

.wrapper {
    &__withSmallerSearchForm {
        display: flex;
        justify-content: center;

        .singleLineSearchForm {
            max-width: $searchform-single-line-small-max-width;
            width: 100%;
        }

        .searchFormFieldContainer {
            &:nth-child(1) {
                flex: 0 0 29%;
                padding-left: 0;
            }

            &:nth-child(2) {
                flex: 1 1 24%;
            }

            &:nth-child(3) {
                flex: 0 0 29%;
            }
        }

        .submitButton {
            flex: 0 1 16%;
        }
    }

    &__smallerExternalSearchForm {
        .searchFormFieldContainer {
            &:nth-child(1) {
                flex: 0 0 23%;
            }
        }

        .submitButton {
            flex: 0 0 14%;
        }
    }

    &__smallestExternalSearchForm {
        .searchFormFieldContainer {
            width: 100%;

            &:nth-child(1) {
                flex: 0 0 20%;
            }

            &:nth-child(2) {
                flex: 0 0 33%;
                min-width: 240px;
            }

            &:nth-child(3) {
                flex: 0 0 27%;
            }
        }

        .submitButton {
            flex: 0 0 20%;
            width: 100%;
            min-width: 140px;
            overflow: hidden;
        }
    }

    &__isExternal {
        min-width: 750px;

        .searchFormFieldContainer {
            padding: 0 3px 5px;
        }
    }

    &__isExternal:not(.wrapper__smallerExternalSearchForm, .wrapper__smallestExternalSearchForm) {
        .searchFormFieldContainer {
            &:nth-child(1) {
                flex: 0 0 25%;
                padding-left: 0;
            }

            &:nth-child(3) {
                flex: 0 0 33%;
            }
        }

        .submitButton {
            flex: 0 1 18%;
            padding-left: 4px;
            height: 59px;
        }
    }
}

.singleLineSearchForm {
    position: relative;

    &__withoutTabs {
        .searchFormFields {
            border-radius: 4px;
        }
    }
}

.searchFormFields {
    display: flex;
    padding: 5px 5px 0;
    background-color: $janJanJanJanJanJanJellow;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba($darkSideOfTheForce, 0.15);

    &__withProductSwitch {
        border-radius: 0 4px 4px;
    }
}

.searchFormFieldContainer {
    padding: 0 2px 5px;

    &:nth-child(1) {
        flex: 0 0 30%;
        padding-left: 0;
    }

    &:nth-child(2) {
        flex: 1;
    }

    &:nth-child(3) {
        flex: 1;
    }
}

.searchFormField {
    position: relative;
    height: 59px;
}

.submitButton {
    flex: 0 1 13%;
    padding-left: 4px;
    height: 59px;
}

// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$award-slider-tuvsaarland2020-name: 'tuvsaarland2020';
$award-slider-tuvsaarland2020-webp: '/assets/images/sprites/1741600581526/award-slider.webp';
$award-slider-tuvsaarland2020-x: 119px;
$award-slider-tuvsaarland2020-y: 316px;
$award-slider-tuvsaarland2020-offset-x: -119px;
$award-slider-tuvsaarland2020-offset-y: -316px;
$award-slider-tuvsaarland2020-width: 111px;
$award-slider-tuvsaarland2020-height: 59px;
$award-slider-tuvsaarland2020-total-width: 506px;
$award-slider-tuvsaarland2020-total-height: 443px;
$award-slider-tuvsaarland2020-image: '/assets/images/sprites/1741600581526/award-slider.png';
$award-slider-tuvsaarland2020: ($award-slider-tuvsaarland2020-x, $award-slider-tuvsaarland2020-y, $award-slider-tuvsaarland2020-offset-x, $award-slider-tuvsaarland2020-offset-y, $award-slider-tuvsaarland2020-width, $award-slider-tuvsaarland2020-height, $award-slider-tuvsaarland2020-total-width, $award-slider-tuvsaarland2020-total-height, $award-slider-tuvsaarland2020-image, $award-slider-tuvsaarland2020-name, $award-slider-tuvsaarland2020-webp, );
$award-slider-tuvsaarland2018-name: 'tuvsaarland2018';
$award-slider-tuvsaarland2018-webp: '/assets/images/sprites/1741600581526/award-slider.webp';
$award-slider-tuvsaarland2018-x: 339px;
$award-slider-tuvsaarland2018-y: 316px;
$award-slider-tuvsaarland2018-offset-x: -339px;
$award-slider-tuvsaarland2018-offset-y: -316px;
$award-slider-tuvsaarland2018-width: 100px;
$award-slider-tuvsaarland2018-height: 53px;
$award-slider-tuvsaarland2018-total-width: 506px;
$award-slider-tuvsaarland2018-total-height: 443px;
$award-slider-tuvsaarland2018-image: '/assets/images/sprites/1741600581526/award-slider.png';
$award-slider-tuvsaarland2018: ($award-slider-tuvsaarland2018-x, $award-slider-tuvsaarland2018-y, $award-slider-tuvsaarland2018-offset-x, $award-slider-tuvsaarland2018-offset-y, $award-slider-tuvsaarland2018-width, $award-slider-tuvsaarland2018-height, $award-slider-tuvsaarland2018-total-width, $award-slider-tuvsaarland2018-total-height, $award-slider-tuvsaarland2018-image, $award-slider-tuvsaarland2018-name, $award-slider-tuvsaarland2018-webp, );
$award-slider-tuvsaarland-name: 'tuvsaarland';
$award-slider-tuvsaarland-webp: '/assets/images/sprites/1741600581526/award-slider.webp';
$award-slider-tuvsaarland-x: 312px;
$award-slider-tuvsaarland-y: 215px;
$award-slider-tuvsaarland-offset-x: -312px;
$award-slider-tuvsaarland-offset-y: -215px;
$award-slider-tuvsaarland-width: 100px;
$award-slider-tuvsaarland-height: 54px;
$award-slider-tuvsaarland-total-width: 506px;
$award-slider-tuvsaarland-total-height: 443px;
$award-slider-tuvsaarland-image: '/assets/images/sprites/1741600581526/award-slider.png';
$award-slider-tuvsaarland: ($award-slider-tuvsaarland-x, $award-slider-tuvsaarland-y, $award-slider-tuvsaarland-offset-x, $award-slider-tuvsaarland-offset-y, $award-slider-tuvsaarland-width, $award-slider-tuvsaarland-height, $award-slider-tuvsaarland-total-width, $award-slider-tuvsaarland-total-height, $award-slider-tuvsaarland-image, $award-slider-tuvsaarland-name, $award-slider-tuvsaarland-webp, );
$award-slider-tuevsaarland2024-name: 'tuevsaarland2024';
$award-slider-tuevsaarland2024-webp: '/assets/images/sprites/1741600581526/award-slider.webp';
$award-slider-tuevsaarland2024-x: 234px;
$award-slider-tuevsaarland2024-y: 316px;
$award-slider-tuevsaarland2024-offset-x: -234px;
$award-slider-tuevsaarland2024-offset-y: -316px;
$award-slider-tuevsaarland2024-width: 101px;
$award-slider-tuevsaarland2024-height: 54px;
$award-slider-tuevsaarland2024-total-width: 506px;
$award-slider-tuevsaarland2024-total-height: 443px;
$award-slider-tuevsaarland2024-image: '/assets/images/sprites/1741600581526/award-slider.png';
$award-slider-tuevsaarland2024: ($award-slider-tuevsaarland2024-x, $award-slider-tuevsaarland2024-y, $award-slider-tuevsaarland2024-offset-x, $award-slider-tuevsaarland2024-offset-y, $award-slider-tuevsaarland2024-width, $award-slider-tuevsaarland2024-height, $award-slider-tuevsaarland2024-total-width, $award-slider-tuevsaarland2024-total-height, $award-slider-tuevsaarland2024-image, $award-slider-tuevsaarland2024-name, $award-slider-tuevsaarland2024-webp, );
$award-slider-servicerating-name: 'servicerating';
$award-slider-servicerating-webp: '/assets/images/sprites/1741600581526/award-slider.webp';
$award-slider-servicerating-x: 416px;
$award-slider-servicerating-y: 0px;
$award-slider-servicerating-offset-x: -416px;
$award-slider-servicerating-offset-y: 0px;
$award-slider-servicerating-width: 90px;
$award-slider-servicerating-height: 100px;
$award-slider-servicerating-total-width: 506px;
$award-slider-servicerating-total-height: 443px;
$award-slider-servicerating-image: '/assets/images/sprites/1741600581526/award-slider.png';
$award-slider-servicerating: ($award-slider-servicerating-x, $award-slider-servicerating-y, $award-slider-servicerating-offset-x, $award-slider-servicerating-offset-y, $award-slider-servicerating-width, $award-slider-servicerating-height, $award-slider-servicerating-total-width, $award-slider-servicerating-total-height, $award-slider-servicerating-image, $award-slider-servicerating-name, $award-slider-servicerating-webp, );
$award-slider-reisepreise-name: 'reisepreise';
$award-slider-reisepreise-webp: '/assets/images/sprites/1741600581526/award-slider.webp';
$award-slider-reisepreise-x: 208px;
$award-slider-reisepreise-y: 215px;
$award-slider-reisepreise-offset-x: -208px;
$award-slider-reisepreise-offset-y: -215px;
$award-slider-reisepreise-width: 100px;
$award-slider-reisepreise-height: 89px;
$award-slider-reisepreise-total-width: 506px;
$award-slider-reisepreise-total-height: 443px;
$award-slider-reisepreise-image: '/assets/images/sprites/1741600581526/award-slider.png';
$award-slider-reisepreise: ($award-slider-reisepreise-x, $award-slider-reisepreise-y, $award-slider-reisepreise-offset-x, $award-slider-reisepreise-offset-y, $award-slider-reisepreise-width, $award-slider-reisepreise-height, $award-slider-reisepreise-total-width, $award-slider-reisepreise-total-height, $award-slider-reisepreise-image, $award-slider-reisepreise-name, $award-slider-reisepreise-webp, );
$award-slider-onlinestar-name: 'onlinestar';
$award-slider-onlinestar-webp: '/assets/images/sprites/1741600581526/award-slider.webp';
$award-slider-onlinestar-x: 104px;
$award-slider-onlinestar-y: 394px;
$award-slider-onlinestar-offset-x: -104px;
$award-slider-onlinestar-offset-y: -394px;
$award-slider-onlinestar-width: 100px;
$award-slider-onlinestar-height: 43px;
$award-slider-onlinestar-total-width: 506px;
$award-slider-onlinestar-total-height: 443px;
$award-slider-onlinestar-image: '/assets/images/sprites/1741600581526/award-slider.png';
$award-slider-onlinestar: ($award-slider-onlinestar-x, $award-slider-onlinestar-y, $award-slider-onlinestar-offset-x, $award-slider-onlinestar-offset-y, $award-slider-onlinestar-width, $award-slider-onlinestar-height, $award-slider-onlinestar-total-width, $award-slider-onlinestar-total-height, $award-slider-onlinestar-image, $award-slider-onlinestar-name, $award-slider-onlinestar-webp, );
$award-slider-n24-name: 'n24';
$award-slider-n24-webp: '/assets/images/sprites/1741600581526/award-slider.webp';
$award-slider-n24-x: 312px;
$award-slider-n24-y: 162px;
$award-slider-n24-offset-x: -312px;
$award-slider-n24-offset-y: -162px;
$award-slider-n24-width: 100px;
$award-slider-n24-height: 49px;
$award-slider-n24-total-width: 506px;
$award-slider-n24-total-height: 443px;
$award-slider-n24-image: '/assets/images/sprites/1741600581526/award-slider.png';
$award-slider-n24: ($award-slider-n24-x, $award-slider-n24-y, $award-slider-n24-offset-x, $award-slider-n24-offset-y, $award-slider-n24-width, $award-slider-n24-height, $award-slider-n24-total-width, $award-slider-n24-total-height, $award-slider-n24-image, $award-slider-n24-name, $award-slider-n24-webp, );
$award-slider-kundenchampion2025-name: 'kundenchampion2025';
$award-slider-kundenchampion2025-webp: '/assets/images/sprites/1741600581526/award-slider.webp';
$award-slider-kundenchampion2025-x: 0px;
$award-slider-kundenchampion2025-y: 0px;
$award-slider-kundenchampion2025-offset-x: 0px;
$award-slider-kundenchampion2025-offset-y: 0px;
$award-slider-kundenchampion2025-width: 100px;
$award-slider-kundenchampion2025-height: 211px;
$award-slider-kundenchampion2025-total-width: 506px;
$award-slider-kundenchampion2025-total-height: 443px;
$award-slider-kundenchampion2025-image: '/assets/images/sprites/1741600581526/award-slider.png';
$award-slider-kundenchampion2025: ($award-slider-kundenchampion2025-x, $award-slider-kundenchampion2025-y, $award-slider-kundenchampion2025-offset-x, $award-slider-kundenchampion2025-offset-y, $award-slider-kundenchampion2025-width, $award-slider-kundenchampion2025-height, $award-slider-kundenchampion2025-total-width, $award-slider-kundenchampion2025-total-height, $award-slider-kundenchampion2025-image, $award-slider-kundenchampion2025-name, $award-slider-kundenchampion2025-webp, );
$award-slider-guterrat2014-name: 'guterrat2014';
$award-slider-guterrat2014-webp: '/assets/images/sprites/1741600581526/award-slider.webp';
$award-slider-guterrat2014-x: 104px;
$award-slider-guterrat2014-y: 215px;
$award-slider-guterrat2014-offset-x: -104px;
$award-slider-guterrat2014-offset-y: -215px;
$award-slider-guterrat2014-width: 100px;
$award-slider-guterrat2014-height: 95px;
$award-slider-guterrat2014-total-width: 506px;
$award-slider-guterrat2014-total-height: 443px;
$award-slider-guterrat2014-image: '/assets/images/sprites/1741600581526/award-slider.png';
$award-slider-guterrat2014: ($award-slider-guterrat2014-x, $award-slider-guterrat2014-y, $award-slider-guterrat2014-offset-x, $award-slider-guterrat2014-offset-y, $award-slider-guterrat2014-width, $award-slider-guterrat2014-height, $award-slider-guterrat2014-total-width, $award-slider-guterrat2014-total-height, $award-slider-guterrat2014-image, $award-slider-guterrat2014-name, $award-slider-guterrat2014-webp, );
$award-slider-focus2024-name: 'focus2024';
$award-slider-focus2024-webp: '/assets/images/sprites/1741600581526/award-slider.webp';
$award-slider-focus2024-x: 208px;
$award-slider-focus2024-y: 0px;
$award-slider-focus2024-offset-x: -208px;
$award-slider-focus2024-offset-y: 0px;
$award-slider-focus2024-width: 100px;
$award-slider-focus2024-height: 186px;
$award-slider-focus2024-total-width: 506px;
$award-slider-focus2024-total-height: 443px;
$award-slider-focus2024-image: '/assets/images/sprites/1741600581526/award-slider.png';
$award-slider-focus2024: ($award-slider-focus2024-x, $award-slider-focus2024-y, $award-slider-focus2024-offset-x, $award-slider-focus2024-offset-y, $award-slider-focus2024-width, $award-slider-focus2024-height, $award-slider-focus2024-total-width, $award-slider-focus2024-total-height, $award-slider-focus2024-image, $award-slider-focus2024-name, $award-slider-focus2024-webp, );
$award-slider-dtgv2023-name: 'dtgv2023';
$award-slider-dtgv2023-webp: '/assets/images/sprites/1741600581526/award-slider.webp';
$award-slider-dtgv2023-x: 312px;
$award-slider-dtgv2023-y: 0px;
$award-slider-dtgv2023-offset-x: -312px;
$award-slider-dtgv2023-offset-y: 0px;
$award-slider-dtgv2023-width: 100px;
$award-slider-dtgv2023-height: 158px;
$award-slider-dtgv2023-total-width: 506px;
$award-slider-dtgv2023-total-height: 443px;
$award-slider-dtgv2023-image: '/assets/images/sprites/1741600581526/award-slider.png';
$award-slider-dtgv2023: ($award-slider-dtgv2023-x, $award-slider-dtgv2023-y, $award-slider-dtgv2023-offset-x, $award-slider-dtgv2023-offset-y, $award-slider-dtgv2023-width, $award-slider-dtgv2023-height, $award-slider-dtgv2023-total-width, $award-slider-dtgv2023-total-height, $award-slider-dtgv2023-image, $award-slider-dtgv2023-name, $award-slider-dtgv2023-webp, );
$award-slider-dtgv2022-name: 'dtgv2022';
$award-slider-dtgv2022-webp: '/assets/images/sprites/1741600581526/award-slider.webp';
$award-slider-dtgv2022-x: 0px;
$award-slider-dtgv2022-y: 394px;
$award-slider-dtgv2022-offset-x: 0px;
$award-slider-dtgv2022-offset-y: -394px;
$award-slider-dtgv2022-width: 100px;
$award-slider-dtgv2022-height: 49px;
$award-slider-dtgv2022-total-width: 506px;
$award-slider-dtgv2022-total-height: 443px;
$award-slider-dtgv2022-image: '/assets/images/sprites/1741600581526/award-slider.png';
$award-slider-dtgv2022: ($award-slider-dtgv2022-x, $award-slider-dtgv2022-y, $award-slider-dtgv2022-offset-x, $award-slider-dtgv2022-offset-y, $award-slider-dtgv2022-width, $award-slider-dtgv2022-height, $award-slider-dtgv2022-total-width, $award-slider-dtgv2022-total-height, $award-slider-dtgv2022-image, $award-slider-dtgv2022-name, $award-slider-dtgv2022-webp, );
$award-slider-disq2023-name: 'disq2023';
$award-slider-disq2023-webp: '/assets/images/sprites/1741600581526/award-slider.webp';
$award-slider-disq2023-x: 104px;
$award-slider-disq2023-y: 0px;
$award-slider-disq2023-offset-x: -104px;
$award-slider-disq2023-offset-y: 0px;
$award-slider-disq2023-width: 100px;
$award-slider-disq2023-height: 200px;
$award-slider-disq2023-total-width: 506px;
$award-slider-disq2023-total-height: 443px;
$award-slider-disq2023-image: '/assets/images/sprites/1741600581526/award-slider.png';
$award-slider-disq2023: ($award-slider-disq2023-x, $award-slider-disq2023-y, $award-slider-disq2023-offset-x, $award-slider-disq2023-offset-y, $award-slider-disq2023-width, $award-slider-disq2023-height, $award-slider-disq2023-total-width, $award-slider-disq2023-total-height, $award-slider-disq2023-image, $award-slider-disq2023-name, $award-slider-disq2023-webp, );
$award-slider-default-name: 'default';
$award-slider-default-webp: '/assets/images/sprites/1741600581526/award-slider.webp';
$award-slider-default-x: 416px;
$award-slider-default-y: 104px;
$award-slider-default-offset-x: -416px;
$award-slider-default-offset-y: -104px;
$award-slider-default-width: 88px;
$award-slider-default-height: 100px;
$award-slider-default-total-width: 506px;
$award-slider-default-total-height: 443px;
$award-slider-default-image: '/assets/images/sprites/1741600581526/award-slider.png';
$award-slider-default: ($award-slider-default-x, $award-slider-default-y, $award-slider-default-offset-x, $award-slider-default-offset-y, $award-slider-default-width, $award-slider-default-height, $award-slider-default-total-width, $award-slider-default-total-height, $award-slider-default-image, $award-slider-default-name, $award-slider-default-webp, );
$award-slider-cleverreisen2021-name: 'cleverreisen2021';
$award-slider-cleverreisen2021-webp: '/assets/images/sprites/1741600581526/award-slider.webp';
$award-slider-cleverreisen2021-x: 0px;
$award-slider-cleverreisen2021-y: 215px;
$award-slider-cleverreisen2021-offset-x: 0px;
$award-slider-cleverreisen2021-offset-y: -215px;
$award-slider-cleverreisen2021-width: 100px;
$award-slider-cleverreisen2021-height: 97px;
$award-slider-cleverreisen2021-total-width: 506px;
$award-slider-cleverreisen2021-total-height: 443px;
$award-slider-cleverreisen2021-image: '/assets/images/sprites/1741600581526/award-slider.png';
$award-slider-cleverreisen2021: ($award-slider-cleverreisen2021-x, $award-slider-cleverreisen2021-y, $award-slider-cleverreisen2021-offset-x, $award-slider-cleverreisen2021-offset-y, $award-slider-cleverreisen2021-width, $award-slider-cleverreisen2021-height, $award-slider-cleverreisen2021-total-width, $award-slider-cleverreisen2021-total-height, $award-slider-cleverreisen2021-image, $award-slider-cleverreisen2021-name, $award-slider-cleverreisen2021-webp, );
$award-slider-bild2021-name: 'bild2021';
$award-slider-bild2021-webp: '/assets/images/sprites/1741600581526/award-slider.webp';
$award-slider-bild2021-x: 0px;
$award-slider-bild2021-y: 316px;
$award-slider-bild2021-offset-x: 0px;
$award-slider-bild2021-offset-y: -316px;
$award-slider-bild2021-width: 115px;
$award-slider-bild2021-height: 74px;
$award-slider-bild2021-total-width: 506px;
$award-slider-bild2021-total-height: 443px;
$award-slider-bild2021-image: '/assets/images/sprites/1741600581526/award-slider.png';
$award-slider-bild2021: ($award-slider-bild2021-x, $award-slider-bild2021-y, $award-slider-bild2021-offset-x, $award-slider-bild2021-offset-y, $award-slider-bild2021-width, $award-slider-bild2021-height, $award-slider-bild2021-total-width, $award-slider-bild2021-total-height, $award-slider-bild2021-image, $award-slider-bild2021-name, $award-slider-bild2021-webp, );
$award-slider-retina-tuvsaarland-name: 'retina_tuvsaarland';
$award-slider-retina-tuvsaarland-webp: '/assets/images/sprites/1741600581526/award-slider_2x.webp';
$award-slider-retina-tuvsaarland-x: 624px;
$award-slider-retina-tuvsaarland-y: 430px;
$award-slider-retina-tuvsaarland-offset-x: -624px;
$award-slider-retina-tuvsaarland-offset-y: -430px;
$award-slider-retina-tuvsaarland-width: 200px;
$award-slider-retina-tuvsaarland-height: 109px;
$award-slider-retina-tuvsaarland-total-width: 1012px;
$award-slider-retina-tuvsaarland-total-height: 887px;
$award-slider-retina-tuvsaarland-image: '/assets/images/sprites/1741600581526/award-slider_2x.png';
$award-slider-retina-tuvsaarland: ($award-slider-retina-tuvsaarland-x, $award-slider-retina-tuvsaarland-y, $award-slider-retina-tuvsaarland-offset-x, $award-slider-retina-tuvsaarland-offset-y, $award-slider-retina-tuvsaarland-width, $award-slider-retina-tuvsaarland-height, $award-slider-retina-tuvsaarland-total-width, $award-slider-retina-tuvsaarland-total-height, $award-slider-retina-tuvsaarland-image, $award-slider-retina-tuvsaarland-name, $award-slider-retina-tuvsaarland-webp, );
$award-slider-retina-tuvsaarland2020-name: 'retina_tuvsaarland2020';
$award-slider-retina-tuvsaarland2020-webp: '/assets/images/sprites/1741600581526/award-slider_2x.webp';
$award-slider-retina-tuvsaarland2020-x: 237px;
$award-slider-retina-tuvsaarland2020-y: 633px;
$award-slider-retina-tuvsaarland2020-offset-x: -237px;
$award-slider-retina-tuvsaarland2020-offset-y: -633px;
$award-slider-retina-tuvsaarland2020-width: 222px;
$award-slider-retina-tuvsaarland2020-height: 117px;
$award-slider-retina-tuvsaarland2020-total-width: 1012px;
$award-slider-retina-tuvsaarland2020-total-height: 887px;
$award-slider-retina-tuvsaarland2020-image: '/assets/images/sprites/1741600581526/award-slider_2x.png';
$award-slider-retina-tuvsaarland2020: ($award-slider-retina-tuvsaarland2020-x, $award-slider-retina-tuvsaarland2020-y, $award-slider-retina-tuvsaarland2020-offset-x, $award-slider-retina-tuvsaarland2020-offset-y, $award-slider-retina-tuvsaarland2020-width, $award-slider-retina-tuvsaarland2020-height, $award-slider-retina-tuvsaarland2020-total-width, $award-slider-retina-tuvsaarland2020-total-height, $award-slider-retina-tuvsaarland2020-image, $award-slider-retina-tuvsaarland2020-name, $award-slider-retina-tuvsaarland2020-webp, );
$award-slider-retina-tuvsaarland2018-name: 'retina_tuvsaarland2018';
$award-slider-retina-tuvsaarland2018-webp: '/assets/images/sprites/1741600581526/award-slider_2x.webp';
$award-slider-retina-tuvsaarland2018-x: 677px;
$award-slider-retina-tuvsaarland2018-y: 633px;
$award-slider-retina-tuvsaarland2018-offset-x: -677px;
$award-slider-retina-tuvsaarland2018-offset-y: -633px;
$award-slider-retina-tuvsaarland2018-width: 200px;
$award-slider-retina-tuvsaarland2018-height: 105px;
$award-slider-retina-tuvsaarland2018-total-width: 1012px;
$award-slider-retina-tuvsaarland2018-total-height: 887px;
$award-slider-retina-tuvsaarland2018-image: '/assets/images/sprites/1741600581526/award-slider_2x.png';
$award-slider-retina-tuvsaarland2018: ($award-slider-retina-tuvsaarland2018-x, $award-slider-retina-tuvsaarland2018-y, $award-slider-retina-tuvsaarland2018-offset-x, $award-slider-retina-tuvsaarland2018-offset-y, $award-slider-retina-tuvsaarland2018-width, $award-slider-retina-tuvsaarland2018-height, $award-slider-retina-tuvsaarland2018-total-width, $award-slider-retina-tuvsaarland2018-total-height, $award-slider-retina-tuvsaarland2018-image, $award-slider-retina-tuvsaarland2018-name, $award-slider-retina-tuvsaarland2018-webp, );
$award-slider-retina-tuevsaarland2024-name: 'retina_tuevsaarland2024';
$award-slider-retina-tuevsaarland2024-webp: '/assets/images/sprites/1741600581526/award-slider_2x.webp';
$award-slider-retina-tuevsaarland2024-x: 467px;
$award-slider-retina-tuevsaarland2024-y: 633px;
$award-slider-retina-tuevsaarland2024-offset-x: -467px;
$award-slider-retina-tuevsaarland2024-offset-y: -633px;
$award-slider-retina-tuevsaarland2024-width: 202px;
$award-slider-retina-tuevsaarland2024-height: 108px;
$award-slider-retina-tuevsaarland2024-total-width: 1012px;
$award-slider-retina-tuevsaarland2024-total-height: 887px;
$award-slider-retina-tuevsaarland2024-image: '/assets/images/sprites/1741600581526/award-slider_2x.png';
$award-slider-retina-tuevsaarland2024: ($award-slider-retina-tuevsaarland2024-x, $award-slider-retina-tuevsaarland2024-y, $award-slider-retina-tuevsaarland2024-offset-x, $award-slider-retina-tuevsaarland2024-offset-y, $award-slider-retina-tuevsaarland2024-width, $award-slider-retina-tuevsaarland2024-height, $award-slider-retina-tuevsaarland2024-total-width, $award-slider-retina-tuevsaarland2024-total-height, $award-slider-retina-tuevsaarland2024-image, $award-slider-retina-tuevsaarland2024-name, $award-slider-retina-tuevsaarland2024-webp, );
$award-slider-retina-servicerating-name: 'retina_servicerating';
$award-slider-retina-servicerating-webp: '/assets/images/sprites/1741600581526/award-slider_2x.webp';
$award-slider-retina-servicerating-x: 832px;
$award-slider-retina-servicerating-y: 0px;
$award-slider-retina-servicerating-offset-x: -832px;
$award-slider-retina-servicerating-offset-y: 0px;
$award-slider-retina-servicerating-width: 180px;
$award-slider-retina-servicerating-height: 200px;
$award-slider-retina-servicerating-total-width: 1012px;
$award-slider-retina-servicerating-total-height: 887px;
$award-slider-retina-servicerating-image: '/assets/images/sprites/1741600581526/award-slider_2x.png';
$award-slider-retina-servicerating: ($award-slider-retina-servicerating-x, $award-slider-retina-servicerating-y, $award-slider-retina-servicerating-offset-x, $award-slider-retina-servicerating-offset-y, $award-slider-retina-servicerating-width, $award-slider-retina-servicerating-height, $award-slider-retina-servicerating-total-width, $award-slider-retina-servicerating-total-height, $award-slider-retina-servicerating-image, $award-slider-retina-servicerating-name, $award-slider-retina-servicerating-webp, );
$award-slider-retina-reisepreise-name: 'retina_reisepreise';
$award-slider-retina-reisepreise-webp: '/assets/images/sprites/1741600581526/award-slider_2x.webp';
$award-slider-retina-reisepreise-x: 416px;
$award-slider-retina-reisepreise-y: 430px;
$award-slider-retina-reisepreise-offset-x: -416px;
$award-slider-retina-reisepreise-offset-y: -430px;
$award-slider-retina-reisepreise-width: 200px;
$award-slider-retina-reisepreise-height: 178px;
$award-slider-retina-reisepreise-total-width: 1012px;
$award-slider-retina-reisepreise-total-height: 887px;
$award-slider-retina-reisepreise-image: '/assets/images/sprites/1741600581526/award-slider_2x.png';
$award-slider-retina-reisepreise: ($award-slider-retina-reisepreise-x, $award-slider-retina-reisepreise-y, $award-slider-retina-reisepreise-offset-x, $award-slider-retina-reisepreise-offset-y, $award-slider-retina-reisepreise-width, $award-slider-retina-reisepreise-height, $award-slider-retina-reisepreise-total-width, $award-slider-retina-reisepreise-total-height, $award-slider-retina-reisepreise-image, $award-slider-retina-reisepreise-name, $award-slider-retina-reisepreise-webp, );
$award-slider-retina-onlinestar-name: 'retina_onlinestar';
$award-slider-retina-onlinestar-webp: '/assets/images/sprites/1741600581526/award-slider_2x.webp';
$award-slider-retina-onlinestar-x: 208px;
$award-slider-retina-onlinestar-y: 789px;
$award-slider-retina-onlinestar-offset-x: -208px;
$award-slider-retina-onlinestar-offset-y: -789px;
$award-slider-retina-onlinestar-width: 200px;
$award-slider-retina-onlinestar-height: 88px;
$award-slider-retina-onlinestar-total-width: 1012px;
$award-slider-retina-onlinestar-total-height: 887px;
$award-slider-retina-onlinestar-image: '/assets/images/sprites/1741600581526/award-slider_2x.png';
$award-slider-retina-onlinestar: ($award-slider-retina-onlinestar-x, $award-slider-retina-onlinestar-y, $award-slider-retina-onlinestar-offset-x, $award-slider-retina-onlinestar-offset-y, $award-slider-retina-onlinestar-width, $award-slider-retina-onlinestar-height, $award-slider-retina-onlinestar-total-width, $award-slider-retina-onlinestar-total-height, $award-slider-retina-onlinestar-image, $award-slider-retina-onlinestar-name, $award-slider-retina-onlinestar-webp, );
$award-slider-retina-n24-name: 'retina_n24';
$award-slider-retina-n24-webp: '/assets/images/sprites/1741600581526/award-slider_2x.webp';
$award-slider-retina-n24-x: 0px;
$award-slider-retina-n24-y: 789px;
$award-slider-retina-n24-offset-x: 0px;
$award-slider-retina-n24-offset-y: -789px;
$award-slider-retina-n24-width: 200px;
$award-slider-retina-n24-height: 98px;
$award-slider-retina-n24-total-width: 1012px;
$award-slider-retina-n24-total-height: 887px;
$award-slider-retina-n24-image: '/assets/images/sprites/1741600581526/award-slider_2x.png';
$award-slider-retina-n24: ($award-slider-retina-n24-x, $award-slider-retina-n24-y, $award-slider-retina-n24-offset-x, $award-slider-retina-n24-offset-y, $award-slider-retina-n24-width, $award-slider-retina-n24-height, $award-slider-retina-n24-total-width, $award-slider-retina-n24-total-height, $award-slider-retina-n24-image, $award-slider-retina-n24-name, $award-slider-retina-n24-webp, );
$award-slider-retina-kundenchampion2025-name: 'retina_kundenchampion2025';
$award-slider-retina-kundenchampion2025-webp: '/assets/images/sprites/1741600581526/award-slider_2x.webp';
$award-slider-retina-kundenchampion2025-x: 0px;
$award-slider-retina-kundenchampion2025-y: 0px;
$award-slider-retina-kundenchampion2025-offset-x: 0px;
$award-slider-retina-kundenchampion2025-offset-y: 0px;
$award-slider-retina-kundenchampion2025-width: 200px;
$award-slider-retina-kundenchampion2025-height: 422px;
$award-slider-retina-kundenchampion2025-total-width: 1012px;
$award-slider-retina-kundenchampion2025-total-height: 887px;
$award-slider-retina-kundenchampion2025-image: '/assets/images/sprites/1741600581526/award-slider_2x.png';
$award-slider-retina-kundenchampion2025: ($award-slider-retina-kundenchampion2025-x, $award-slider-retina-kundenchampion2025-y, $award-slider-retina-kundenchampion2025-offset-x, $award-slider-retina-kundenchampion2025-offset-y, $award-slider-retina-kundenchampion2025-width, $award-slider-retina-kundenchampion2025-height, $award-slider-retina-kundenchampion2025-total-width, $award-slider-retina-kundenchampion2025-total-height, $award-slider-retina-kundenchampion2025-image, $award-slider-retina-kundenchampion2025-name, $award-slider-retina-kundenchampion2025-webp, );
$award-slider-retina-guterrat2014-name: 'retina_guterrat2014';
$award-slider-retina-guterrat2014-webp: '/assets/images/sprites/1741600581526/award-slider_2x.webp';
$award-slider-retina-guterrat2014-x: 208px;
$award-slider-retina-guterrat2014-y: 430px;
$award-slider-retina-guterrat2014-offset-x: -208px;
$award-slider-retina-guterrat2014-offset-y: -430px;
$award-slider-retina-guterrat2014-width: 200px;
$award-slider-retina-guterrat2014-height: 190px;
$award-slider-retina-guterrat2014-total-width: 1012px;
$award-slider-retina-guterrat2014-total-height: 887px;
$award-slider-retina-guterrat2014-image: '/assets/images/sprites/1741600581526/award-slider_2x.png';
$award-slider-retina-guterrat2014: ($award-slider-retina-guterrat2014-x, $award-slider-retina-guterrat2014-y, $award-slider-retina-guterrat2014-offset-x, $award-slider-retina-guterrat2014-offset-y, $award-slider-retina-guterrat2014-width, $award-slider-retina-guterrat2014-height, $award-slider-retina-guterrat2014-total-width, $award-slider-retina-guterrat2014-total-height, $award-slider-retina-guterrat2014-image, $award-slider-retina-guterrat2014-name, $award-slider-retina-guterrat2014-webp, );
$award-slider-retina-focus2024-name: 'retina_focus2024';
$award-slider-retina-focus2024-webp: '/assets/images/sprites/1741600581526/award-slider_2x.webp';
$award-slider-retina-focus2024-x: 416px;
$award-slider-retina-focus2024-y: 0px;
$award-slider-retina-focus2024-offset-x: -416px;
$award-slider-retina-focus2024-offset-y: 0px;
$award-slider-retina-focus2024-width: 200px;
$award-slider-retina-focus2024-height: 372px;
$award-slider-retina-focus2024-total-width: 1012px;
$award-slider-retina-focus2024-total-height: 887px;
$award-slider-retina-focus2024-image: '/assets/images/sprites/1741600581526/award-slider_2x.png';
$award-slider-retina-focus2024: ($award-slider-retina-focus2024-x, $award-slider-retina-focus2024-y, $award-slider-retina-focus2024-offset-x, $award-slider-retina-focus2024-offset-y, $award-slider-retina-focus2024-width, $award-slider-retina-focus2024-height, $award-slider-retina-focus2024-total-width, $award-slider-retina-focus2024-total-height, $award-slider-retina-focus2024-image, $award-slider-retina-focus2024-name, $award-slider-retina-focus2024-webp, );
$award-slider-retina-dtgv2023-name: 'retina_dtgv2023';
$award-slider-retina-dtgv2023-webp: '/assets/images/sprites/1741600581526/award-slider_2x.webp';
$award-slider-retina-dtgv2023-x: 624px;
$award-slider-retina-dtgv2023-y: 0px;
$award-slider-retina-dtgv2023-offset-x: -624px;
$award-slider-retina-dtgv2023-offset-y: 0px;
$award-slider-retina-dtgv2023-width: 200px;
$award-slider-retina-dtgv2023-height: 315px;
$award-slider-retina-dtgv2023-total-width: 1012px;
$award-slider-retina-dtgv2023-total-height: 887px;
$award-slider-retina-dtgv2023-image: '/assets/images/sprites/1741600581526/award-slider_2x.png';
$award-slider-retina-dtgv2023: ($award-slider-retina-dtgv2023-x, $award-slider-retina-dtgv2023-y, $award-slider-retina-dtgv2023-offset-x, $award-slider-retina-dtgv2023-offset-y, $award-slider-retina-dtgv2023-width, $award-slider-retina-dtgv2023-height, $award-slider-retina-dtgv2023-total-width, $award-slider-retina-dtgv2023-total-height, $award-slider-retina-dtgv2023-image, $award-slider-retina-dtgv2023-name, $award-slider-retina-dtgv2023-webp, );
$award-slider-retina-dtgv2022-name: 'retina_dtgv2022';
$award-slider-retina-dtgv2022-webp: '/assets/images/sprites/1741600581526/award-slider_2x.webp';
$award-slider-retina-dtgv2022-x: 624px;
$award-slider-retina-dtgv2022-y: 323px;
$award-slider-retina-dtgv2022-offset-x: -624px;
$award-slider-retina-dtgv2022-offset-y: -323px;
$award-slider-retina-dtgv2022-width: 200px;
$award-slider-retina-dtgv2022-height: 99px;
$award-slider-retina-dtgv2022-total-width: 1012px;
$award-slider-retina-dtgv2022-total-height: 887px;
$award-slider-retina-dtgv2022-image: '/assets/images/sprites/1741600581526/award-slider_2x.png';
$award-slider-retina-dtgv2022: ($award-slider-retina-dtgv2022-x, $award-slider-retina-dtgv2022-y, $award-slider-retina-dtgv2022-offset-x, $award-slider-retina-dtgv2022-offset-y, $award-slider-retina-dtgv2022-width, $award-slider-retina-dtgv2022-height, $award-slider-retina-dtgv2022-total-width, $award-slider-retina-dtgv2022-total-height, $award-slider-retina-dtgv2022-image, $award-slider-retina-dtgv2022-name, $award-slider-retina-dtgv2022-webp, );
$award-slider-retina-disq2023-name: 'retina_disq2023';
$award-slider-retina-disq2023-webp: '/assets/images/sprites/1741600581526/award-slider_2x.webp';
$award-slider-retina-disq2023-x: 208px;
$award-slider-retina-disq2023-y: 0px;
$award-slider-retina-disq2023-offset-x: -208px;
$award-slider-retina-disq2023-offset-y: 0px;
$award-slider-retina-disq2023-width: 200px;
$award-slider-retina-disq2023-height: 399px;
$award-slider-retina-disq2023-total-width: 1012px;
$award-slider-retina-disq2023-total-height: 887px;
$award-slider-retina-disq2023-image: '/assets/images/sprites/1741600581526/award-slider_2x.png';
$award-slider-retina-disq2023: ($award-slider-retina-disq2023-x, $award-slider-retina-disq2023-y, $award-slider-retina-disq2023-offset-x, $award-slider-retina-disq2023-offset-y, $award-slider-retina-disq2023-width, $award-slider-retina-disq2023-height, $award-slider-retina-disq2023-total-width, $award-slider-retina-disq2023-total-height, $award-slider-retina-disq2023-image, $award-slider-retina-disq2023-name, $award-slider-retina-disq2023-webp, );
$award-slider-retina-default-name: 'retina_default';
$award-slider-retina-default-webp: '/assets/images/sprites/1741600581526/award-slider_2x.webp';
$award-slider-retina-default-x: 832px;
$award-slider-retina-default-y: 208px;
$award-slider-retina-default-offset-x: -832px;
$award-slider-retina-default-offset-y: -208px;
$award-slider-retina-default-width: 176px;
$award-slider-retina-default-height: 200px;
$award-slider-retina-default-total-width: 1012px;
$award-slider-retina-default-total-height: 887px;
$award-slider-retina-default-image: '/assets/images/sprites/1741600581526/award-slider_2x.png';
$award-slider-retina-default: ($award-slider-retina-default-x, $award-slider-retina-default-y, $award-slider-retina-default-offset-x, $award-slider-retina-default-offset-y, $award-slider-retina-default-width, $award-slider-retina-default-height, $award-slider-retina-default-total-width, $award-slider-retina-default-total-height, $award-slider-retina-default-image, $award-slider-retina-default-name, $award-slider-retina-default-webp, );
$award-slider-retina-cleverreisen2021-name: 'retina_cleverreisen2021';
$award-slider-retina-cleverreisen2021-webp: '/assets/images/sprites/1741600581526/award-slider_2x.webp';
$award-slider-retina-cleverreisen2021-x: 0px;
$award-slider-retina-cleverreisen2021-y: 430px;
$award-slider-retina-cleverreisen2021-offset-x: 0px;
$award-slider-retina-cleverreisen2021-offset-y: -430px;
$award-slider-retina-cleverreisen2021-width: 200px;
$award-slider-retina-cleverreisen2021-height: 195px;
$award-slider-retina-cleverreisen2021-total-width: 1012px;
$award-slider-retina-cleverreisen2021-total-height: 887px;
$award-slider-retina-cleverreisen2021-image: '/assets/images/sprites/1741600581526/award-slider_2x.png';
$award-slider-retina-cleverreisen2021: ($award-slider-retina-cleverreisen2021-x, $award-slider-retina-cleverreisen2021-y, $award-slider-retina-cleverreisen2021-offset-x, $award-slider-retina-cleverreisen2021-offset-y, $award-slider-retina-cleverreisen2021-width, $award-slider-retina-cleverreisen2021-height, $award-slider-retina-cleverreisen2021-total-width, $award-slider-retina-cleverreisen2021-total-height, $award-slider-retina-cleverreisen2021-image, $award-slider-retina-cleverreisen2021-name, $award-slider-retina-cleverreisen2021-webp, );
$award-slider-retina-bild2021-name: 'retina_bild2021';
$award-slider-retina-bild2021-webp: '/assets/images/sprites/1741600581526/award-slider_2x.webp';
$award-slider-retina-bild2021-x: 0px;
$award-slider-retina-bild2021-y: 633px;
$award-slider-retina-bild2021-offset-x: 0px;
$award-slider-retina-bild2021-offset-y: -633px;
$award-slider-retina-bild2021-width: 229px;
$award-slider-retina-bild2021-height: 148px;
$award-slider-retina-bild2021-total-width: 1012px;
$award-slider-retina-bild2021-total-height: 887px;
$award-slider-retina-bild2021-image: '/assets/images/sprites/1741600581526/award-slider_2x.png';
$award-slider-retina-bild2021: ($award-slider-retina-bild2021-x, $award-slider-retina-bild2021-y, $award-slider-retina-bild2021-offset-x, $award-slider-retina-bild2021-offset-y, $award-slider-retina-bild2021-width, $award-slider-retina-bild2021-height, $award-slider-retina-bild2021-total-width, $award-slider-retina-bild2021-total-height, $award-slider-retina-bild2021-image, $award-slider-retina-bild2021-name, $award-slider-retina-bild2021-webp, );
$award-slider-width: 506px;
$award-slider-height: 443px;
$award-slider-image: '/assets/images/sprites/1741600581526/award-slider.png';
$award-slider-sprites: ($award-slider-tuvsaarland2020, $award-slider-tuvsaarland2018, $award-slider-tuvsaarland, $award-slider-tuevsaarland2024, $award-slider-servicerating, $award-slider-reisepreise, $award-slider-onlinestar, $award-slider-n24, $award-slider-kundenchampion2025, $award-slider-guterrat2014, $award-slider-focus2024, $award-slider-dtgv2023, $award-slider-dtgv2022, $award-slider-disq2023, $award-slider-default, $award-slider-cleverreisen2021, $award-slider-bild2021, );
$award-slider: ($award-slider-width, $award-slider-height, $award-slider-image, $award-slider-sprites, );
$award-slider-retina-spritesheet-width: 1012px;
$award-slider-retina-spritesheet-height: 887px;
$award-slider-retina-spritesheet-image: '/assets/images/sprites/1741600581526/award-slider_2x.png';
$award-slider-retina-spritesheet-sprites: ($award-slider-retina-tuvsaarland, $award-slider-retina-tuvsaarland2020, $award-slider-retina-tuvsaarland2018, $award-slider-retina-tuevsaarland2024, $award-slider-retina-servicerating, $award-slider-retina-reisepreise, $award-slider-retina-onlinestar, $award-slider-retina-n24, $award-slider-retina-kundenchampion2025, $award-slider-retina-guterrat2014, $award-slider-retina-focus2024, $award-slider-retina-dtgv2023, $award-slider-retina-dtgv2022, $award-slider-retina-disq2023, $award-slider-retina-default, $award-slider-retina-cleverreisen2021, $award-slider-retina-bild2021, );
$award-slider-retina-spritesheet: ($award-slider-retina-spritesheet-width, $award-slider-retina-spritesheet-height, $award-slider-retina-spritesheet-image, $award-slider-retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$award-slider-tuvsaarland2020-group-name: 'tuvsaarland2020';
$award-slider-tuvsaarland2020-group: ('tuvsaarland2020', $award-slider-tuvsaarland2020, $award-slider-retina-tuvsaarland, );
$award-slider-tuvsaarland2018-group-name: 'tuvsaarland2018';
$award-slider-tuvsaarland2018-group: ('tuvsaarland2018', $award-slider-tuvsaarland2018, $award-slider-retina-tuvsaarland2020, );
$award-slider-tuvsaarland-group-name: 'tuvsaarland';
$award-slider-tuvsaarland-group: ('tuvsaarland', $award-slider-tuvsaarland, $award-slider-retina-tuvsaarland2018, );
$award-slider-tuevsaarland2024-group-name: 'tuevsaarland2024';
$award-slider-tuevsaarland2024-group: ('tuevsaarland2024', $award-slider-tuevsaarland2024, $award-slider-retina-tuevsaarland2024, );
$award-slider-servicerating-group-name: 'servicerating';
$award-slider-servicerating-group: ('servicerating', $award-slider-servicerating, $award-slider-retina-servicerating, );
$award-slider-reisepreise-group-name: 'reisepreise';
$award-slider-reisepreise-group: ('reisepreise', $award-slider-reisepreise, $award-slider-retina-reisepreise, );
$award-slider-onlinestar-group-name: 'onlinestar';
$award-slider-onlinestar-group: ('onlinestar', $award-slider-onlinestar, $award-slider-retina-onlinestar, );
$award-slider-n24-group-name: 'n24';
$award-slider-n24-group: ('n24', $award-slider-n24, $award-slider-retina-n24, );
$award-slider-kundenchampion2025-group-name: 'kundenchampion2025';
$award-slider-kundenchampion2025-group: ('kundenchampion2025', $award-slider-kundenchampion2025, $award-slider-retina-kundenchampion2025, );
$award-slider-guterrat2014-group-name: 'guterrat2014';
$award-slider-guterrat2014-group: ('guterrat2014', $award-slider-guterrat2014, $award-slider-retina-guterrat2014, );
$award-slider-focus2024-group-name: 'focus2024';
$award-slider-focus2024-group: ('focus2024', $award-slider-focus2024, $award-slider-retina-focus2024, );
$award-slider-dtgv2023-group-name: 'dtgv2023';
$award-slider-dtgv2023-group: ('dtgv2023', $award-slider-dtgv2023, $award-slider-retina-dtgv2023, );
$award-slider-dtgv2022-group-name: 'dtgv2022';
$award-slider-dtgv2022-group: ('dtgv2022', $award-slider-dtgv2022, $award-slider-retina-dtgv2022, );
$award-slider-disq2023-group-name: 'disq2023';
$award-slider-disq2023-group: ('disq2023', $award-slider-disq2023, $award-slider-retina-disq2023, );
$award-slider-default-group-name: 'default';
$award-slider-default-group: ('default', $award-slider-default, $award-slider-retina-default, );
$award-slider-cleverreisen2021-group-name: 'cleverreisen2021';
$award-slider-cleverreisen2021-group: ('cleverreisen2021', $award-slider-cleverreisen2021, $award-slider-retina-cleverreisen2021, );
$award-slider-bild2021-group-name: 'bild2021';
$award-slider-bild2021-group: ('bild2021', $award-slider-bild2021, $award-slider-retina-bild2021, );
$award-slider-retina-groups: ($award-slider-tuvsaarland2020-group, $award-slider-tuvsaarland2018-group, $award-slider-tuvsaarland-group, $award-slider-tuevsaarland2024-group, $award-slider-servicerating-group, $award-slider-reisepreise-group, $award-slider-onlinestar-group, $award-slider-n24-group, $award-slider-kundenchampion2025-group, $award-slider-guterrat2014-group, $award-slider-focus2024-group, $award-slider-dtgv2023-group, $award-slider-dtgv2022-group, $award-slider-disq2023-group, $award-slider-default-group, $award-slider-cleverreisen2021-group, $award-slider-bild2021-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include award-slider-sprite-width($icon-home);
// }
//
// .icon-email {
//   @include award-slider-sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin award-slider-sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin award-slider-sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin award-slider-sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin award-slider-sprite-image($sprite) {
    $sprite-image: nth($sprite, 9);
    $sprite-image-webp: nth($sprite, 11);
    background-image: url(#{$sprite-image});
    :global .webp & {
        background-image: url(#{$sprite-image-webp});
    }
}

@mixin award-slider-sprite($sprite) {
  @include award-slider-sprite-image($sprite);
  @include award-slider-sprite-position($sprite);
  @include award-slider-sprite-width($sprite);
  @include award-slider-sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include award-slider-retina-sprite($icon-home-group);
// }
@mixin award-slider-sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin award-slider-retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include award-slider-sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include award-slider-sprite-image($retina-sprite);
    @include award-slider-sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include award-slider-sprites($spritesheet-sprites);
@mixin award-slider-sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include award-slider-sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include award-slider-retina-sprites($retina-groups);
@mixin award-slider-retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include award-slider-retina-sprite($retina-group);
    }
  }
}

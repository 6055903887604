@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/assets/assets';

.container {
    padding: $global-spacing 10px;
}

.headline {
    font-size: 20px;
    margin-bottom: $global-spacing;
    line-height: 1.4;
}

.image {
    position: relative;
    background: url($travelCommunityTeaserBackgroundJpg) no-repeat bottom;
    background-size: cover;
    border-radius: 4px;
    height: 110px;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 50%;
        background: linear-gradient(to top, rgba($darkSideOfTheForce, 0.5), rgba($darkSideOfTheForce, 0));
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.subHeadlineContainer {
    position: absolute;
    left: 0;
    bottom: 24px;
    padding: 3px 10px;
    font-size: 14px;
    font-weight: bold;
    color: $sophieWhite;
    background-color: rgba($sophieWhite, 0.3);
}

.name {
    color: $buckIsBluetiful;
}

.description {
    position: absolute;
    bottom: 5px;
    left: 10px;
    right: 0;
    font-size: 9px;
    color: $sophieWhite;

    @media screen and (width <= 385px) {
        font-size: 8px;
    }
}

.link {
    display: block;
    font-size: 14px;
    margin-top: $global-spacing;
    text-align: end;
}

@import 'Application/view/generated/colors';

// Padding in various components e.g. SearchForm and DateRangePicker
$componentPadding: 15px !default;

// Generic form
$searchFormInputHeight: 52px !default;
$searchFormMobileInputHeight: 42px !default;
$searchFormMobileInputWithLabelHeight: 52px !default;

/**
 * Map related
 */
$hotelsMapSideBarMinWidth: 240px !default;
$hotelsMapSideBarRightPadding: 15px !default;
$hotelsMapPadding: 15px !default;
$hotelsMapBorderRadius: 5px !default;
$hotelsMapBigResultWidth: 520px !default;
$hotelMapFiltersWidth: 240px !default;
$hotelMapResultsListWidth: 280px !default;
$clusterResultsOverlayContentHeaderHeight: 63px !default;
$hotelMapSideBarWidthCollapsed: $hotelMapFiltersWidth !default;
$hotelMapSideBarWidthExpanded: $hotelMapFiltersWidth + $hotelMapResultsListWidth !default;
$hotelMapFiltersPadding: 15px !default;
$hotelsMapBaseTransitionSpeed: 0.2s !default;
$hotelMapSearchInfoHeaderZIndex: 4 !default;
$hotelMapFiltersZIndex: 3 !default;
$hotelMapToggleZIndex: 2 !default;
$hotelMapResultsListZIndex: 1 !default;
$mapMarkerPriceFontFamily: Roboto, Verdana, Arial, Helvetica, sans-serif;

// Size of vertical margin or padding applied to components on offers page
$verticalGap: 20px;
$verticalGapMedium: 30px;
$verticalGapLarge: 45px;
$borderRadius: 4px;
$boxHoverTransition:
    border 0.25s ease-out,
    box-shadow 0.25s ease-out;
$boxShadow: 0 0 8px 0 rgba($darkSideOfTheForce, 0.1);
$boxShadowGrey: 0 0 0 1px $marktMaedels;
$boxShadowHover: 0 0 8px 0 rgba($darkSideOfTheForce, 0.4);
$formOverlayGapBetweenInput: 10px;
$formOverlayBorderRadius: 12px;
$formOverlayPadding: 20px;

/**
 * Result tile related
 */
$resultTileImageWidth: 264px !default;
$resultTileImageHeight: 264px !default;
$resultTileNarrowImageWith: 204px !default;
$resultTileTransitionSpeed: 0.4s !default;

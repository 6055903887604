@import 'Application/view/generated/colors';
@import 'Application/view/generated/assets/assets';
@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/zIndices';
@import 'Application/view/homes/css-components/variables';
@import 'Application/view/search-form/components/tooltips/searchFormTooltipContainer';

.destinationSuggestionContainer {
    @include searchFormToolTipContainer;

    display: none;
    position: absolute;
    max-width: 600px;
    min-width: 520px;
    padding: 0 $global-spacing 9px;
    font-size: 14px;
    z-index: zIndex(partialOverlays) + 2;

    &__open {
        display: block;
    }

    &__countryInformationOverlay {
        margin-top: 4px;
    }

    &__resultsPage {
        margin-top: 5px;
        font-size: 13px;
    }

    &__frontPage {
        margin-top: 10px;
        font-size: 13px;
    }

    &__offersPage {
        margin-top: 5px;
        z-index: zIndex(flyingOverContentElements);
    }
}

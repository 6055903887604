@import 'Application/view/generated/colors';

.travelCenterLink {
    color: $sophieWhite;
    line-height: 14px;

    &:hover {
        color: $sophieWhite;
    }

    @media only screen and (width <= 1680px) {
        display: none;
    }

    &__tablet {
        line-height: unset;
    }
}

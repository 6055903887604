@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.container {
    text-align: center;
}

.header {
    font-weight: bold;
    font-size: 23px;
    padding-bottom: $global-spacing-large;
}

.tile {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-align: left;
}

.image {
    max-height: 156px;
    width: 100%;
    object-fit: cover;
    border-radius: $global-radius;
}

.label {
    color: $silverStar;
    padding-top: $global-spacing-smedium;
    font-size: 16px;
}

.count {
    color: $silverStar;
    font-size: 12px;
}

@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/mixins/frontPage_mixins';

.provisionHint {
    background-color: $almostWhite;
    margin-bottom: $frontpage-headlines-margin-bottom;
    margin-top: $landingpage-top-level-element-spacing;

    &__isStartPageAttackFeatureActive {
        margin-bottom: 0;
        margin-top: 0;

        .headline {
            margin-bottom: $startpage-attack-headlines-margin-bottom;
        }
    }
}

.content {
    width: 100%;
    padding: 45px 15px 15px;
    text-align: center;
    max-width: $global-width;
    margin: 0 auto;

    @media screen and (width >= 1024px) {
        max-width: 1310px;
    }
}

.headline {
    @include frontPageHeadline;

    margin-bottom: 30px;
    line-height: 1;
    color: $silverStar;

    &__lessMargin {
        margin-bottom: $startpage-attack-headlines-margin-bottom;
    }
}

.column {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 15px;
}

.subHeadlines {
    margin-bottom: $frontpage-headlines-margin-bottom;
    display: flex;
}

.subHeadline {
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 1;
}

.text {
    line-height: 18px;
}

.textButton {
    line-height: 1;
    font-size: 14px;
}

@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'FrontPage/view/frontPage/components/travel-community/variables';

.tile {
    display: flex;
    flex-direction: column;

    &__desktop {
        flex: 1;

        .image {
            // Overwrites the default style from foundation.
            height: $travelCommunityTileImageHeight;
        }

        &.tile__withFullWidthImage {
            .bottomRow {
                justify-content: flex-start;
            }
        }
    }

    &__mobile {
        .image {
            // Overwrites the default style from foundation.
            height: 196px;
        }
    }
}

.imageWrapper {
    display: inline-flex;
}

.image {
    flex: 1;
    border-radius: 4px;
    object-fit: cover;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: $global-spacing-smedium;
    color: $silverStar;
}

.location,
.subLine {
    &,
    &:hover,
    &:focus {
        color: $silverStar;
    }
}

.location {
    font-weight: bold;
    font-size: 16px;
}

.subLine {
    margin-top: $global-spacing-xsmall;
    font-size: 14px;
}

.bottomRow {
    margin-top: $global-spacing-small;
    display: flex;
    gap: $global-spacing;
    justify-content: space-between;
    width: 100%;
}

.avatar {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    object-fit: cover;

    + .avatar {
        // The avatars should slightly overlap.
        margin-left: -2px;
    }
}

.badge {
    border-radius: 30px;
    font-size: 14px;
    font-weight: bold;
    background-color: $almostWhite;
    padding: $global-spacing-xsmall $global-spacing;

    &,
    &:hover,
    &:focus {
        color: $scorpion;
    }
}

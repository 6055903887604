@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.advantageItemDesktop {
    font-size: 12px;
    display: flex;
    align-items: center;
    column-gap: $global-spacing-smedium;
    flex: 1;
    padding: $global-spacing;
    border-radius: 20px;
    border: 1px solid $grey;
    background: $sophieWhite;
}

.itemLogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.itemLogo {
    font-size: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    line-height: 1;
}

.advantageItemHeadline {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: $global-spacing-small;
}

.innerContent {
    font-size: 12px;
}

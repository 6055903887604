@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/mixins/c24Sprite';

$iconWidth: 62px;
$iconHeight: 62px;
$iconMargin: 16px;

.ekomiWidget {
    color: $silverStar;
    font-size: 12px;

    &__desktop,
    &__tablet {
        .latestRatings {
            line-height: normal;
        }

        .starsContainer {
            display: inline-block;
        }

        .averageRating {
            margin-left: 5px;
            display: inline-block;
            vertical-align: top;
            white-space: nowrap;
        }
    }

    &__mobile {
        font-size: 14px;
        padding-top: $global-spacing-small;

        .starsContainer {
            display: inline-block;
            margin-bottom: 5px;
        }

        .averageRating {
            display: inline-block;
            vertical-align: top;
            margin-left: 5px;
            color: $silverStar;
            font-size: 18px;
        }

        .overallRatings,
        .latestRatings {
            font-size: 16px;
            color: $silverStar;
        }

        .latestRatings {
            line-height: 18px;
            padding-top: 2px;
        }

        .overallRatings {
            font-weight: bold;
            margin-top: 2px;
        }

        .clickoutLink {
            display: block;
            text-align: right;
            padding-bottom: $global-spacing;
            font-size: 16px;
        }

        .iconRow {
            display: flex;
            align-self: center;
        }
    }
}

.upperPart {
    display: flex;
    column-gap: $global-spacing;
}

.icon {
    @include c24Sprite('ekomi');

    transform: scale(0.9);
    transform-origin: right;
    display: inline-block;
    width: $iconWidth;
    height: $iconHeight;

    &__es {
        @include c24Sprite('ekomiEs');
    }
}

.starsContainer {
    position: relative;
}

.starsBackground {
    @include c24Sprite('starGrey');
}

.stars {
    @include c24Sprite('star');

    position: absolute;
    top: 0;
    left: 0;
}

.averageRating {
    font-size: 20px;
}

.latestRatings {
    margin-bottom: 0;
}

.overallRatings {
    margin-top: $global-spacing-small;
    font-weight: bold;
}

.clickoutContainer {
    text-align: right;
}

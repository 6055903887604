@import 'mixins';
@import 'Application/view/framework/common/settings';
@import 'Icons/view/generated/icons';
@import 'Application/view/generated/colors';

@mixin frontPageHeadline($withTextOrientation: true) {
    margin-bottom: $global-spacing;
    font-size: 23px;
    font-weight: bold;

    @if $withTextOrientation {
        text-align: center;
    }
}

@mixin goldClubCheckIcon($iconHeight) {
    font-weight: normal;
    line-height: 1;

    &:before {
        @include iconFont;

        font-size: $iconHeight;
        line-height: $iconHeight;
        content: $checkIcon;
        color: $goldieMcGoldface;
    }
}

@import 'Application/view/generated/colors';

.invertedInfoIcon {
    font-size: 12px;
    line-height: 1;

    &__light {
        color: $dustyGrey;
    }

    &__dark {
        color: $doveGrey;
    }
}

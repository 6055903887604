@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/outlineNone';
@import 'Application/view/framework/common/tooltip/variables';

.bigDateRangePickerInput {
    position: relative;

    @include outlineNone;
}

$dayBoxSize: 34px;
$dayPadding: 5px;

.wrapper {
    @include outlineNone;

    width: 100%;
    height: 257px;
    position: relative;
    padding: 0 $global-spacing;
    user-select: none;
}

.nav {
    display: flex;
    width: 100%;
}

.navButtonPrev {
    // Overwriting default button styles.
    background: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    position: absolute;
    top: $global-spacing-xsmall;
    left: 0;
}

.navButtonNext {
    // Overwriting default button styles.
    background: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    position: absolute;
    top: $global-spacing-xsmall;
    right: 0;
    margin-left: auto;
}

.table {
    width: 100%;
}

$monthGap: 30px;

.months {
    display: flex;
    width: 100%;
    column-gap: $monthGap;
}

.month {
    position: relative;
    flex: 0 0 calc(50% - #{$monthGap * 0.5});
}

.weekRow {
    display: flex;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.day {
    @include outlineNone;

    flex: 1 0 20px;
    display: flex;
    justify-content: center;
    position: relative;
    margin: 0;
    padding: 0;
}

.dayContent {
    // Overwriting default button styles.
    border: 0;
    appearance: none;
    background: transparent;
    border-radius: 0;
    line-height: 1.5;
    padding: $dayPadding;
    width: 100%;
    color: $silverStar;
    font-size: 14px;
    cursor: pointer;

    &__arrival,
    &__departure {
        background-color: $blueFire;
        color: $sophieWhite;
    }

    &__inBetweenEnteredDates,
    &__inBetweenSelectedDates {
        background-color: $frosteon;
        color: $silverStar;
    }

    /* stylelint-disable-next-line selector-not-notation */
    &:hover:not(.dayContent__disabled):not(.outsideMonthDay) {
        background-color: $denim;
        color: $sophieWhite;
    }

    &__disabled {
        border-color: transparent;
        background-color: transparent;
        cursor: default;
        color: $marktMaedels;
    }
}

.outsideMonthDay {
    color: transparent;
}

.footer {
    text-align: center;
    margin: 0 $global-spacing;
    padding-top: $global-spacing;
    border-top: 1px solid $marktMaedels;
    color: $doveGrey;
}

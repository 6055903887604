@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.locationString {
    font-size: 12px;
    color: $silverStar;
    margin-top: 10px;
    margin-right: $global-spacing-small;
    display: flex;

    &__withoutMargin {
        margin: 0;
    }
}

.locationContainer {
    display: flex;
    flex-direction: row;
    cursor: help;

    &__desktop,
    &__tablet {
        align-items: center;
    }
}

.markerIcon {
    line-height: 12px;
    margin-right: 6px;

    &__desktop,
    &__tablet {
        padding-top: 1px;
    }
}

.pointsOfInterestHint {
    white-space: nowrap;
}

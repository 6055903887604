@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.effectivePriceTooltip {
    --price-overview-font-size: 14px;
}

.title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: $global-spacing-smedium;
}

.separator {
    margin-top: $global-spacing-smedium;
    margin-bottom: $global-spacing-smedium;
    height: 1px;
    background: $marktMaedels;
}

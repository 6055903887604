@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.closeIcon {
    font-size: 25px;
    line-height: 1;
    color: $sophieWhite;
    transition: 0.25s color;
    cursor: pointer;
}

@import 'Application/view/generated/colors';

.spacer {
    position: relative;
    background-color: $sophieWhite;

    &__lightBlueBackground {
        background-color: $frosteon;
    }

    &__almostWhiteBackground {
        background-color: $almostWhite;
    }

    &__frosteonBackground {
        background-color: $frosteon;
    }

    &__pummeluffPinkBackground {
        background-color: $pummeluffPink;
    }
}

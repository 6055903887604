@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.recommendedRoomConfiguration {
    display: flex;
    flex-flow: column;
    padding-left: $global-spacing;
    margin-left: $global-spacing;
    border-left: 1px solid $marktMaedels;
    font-size: 14px;
    min-width: 250px;
    row-gap: $global-spacing-small;

    &__mobile {
        font-size: 16px;
        border-left: 0;
        border-top: 1px solid $marktMaedels;
        padding: $global-spacing 0 0 0;
        margin: $global-spacing 0 0 0;

        .room {
            display: block;
        }

        .roomIndex {
            font-weight: bold;
        }
    }
}

.headline {
    white-space: nowrap;
    font-weight: bold;
}

.room {
    display: flex;
    white-space: nowrap;

    &__roomFail {
        color: $dustyGrey;
    }
}

.roomIndex {
    width: 100px;
}

.changeRoomLink {
    text-align: right;
}

@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

$dividerHeight: 26px;

.sampleCalculationDesktop {
    width: 420px;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.headline {
    font-weight: bold;
    margin-bottom: $global-spacing;
}

.savingTrack {
    margin-top: 60px;
    height: 50px;
    width: 90%;
}

.cheapestPrice,
.priciestPrice {
    display: inline-block;
    position: relative;
    min-height: 10px;
}

.cheapestPrice {
    background-color: $blueFire;
    width: 30%;
}

.priciestPrice {
    background-color: $chatbotBaumGreen;
    width: 60%;
}

.cheapestProviderLabel,
.priciestProviderLabel {
    position: absolute;
    top: -8px;
    right: 0;
    width: 140px;
    white-space: normal;
    text-align: center;
    border: 1px solid currentColor;
    transform: translateX(50%) translateY(-100%);
    padding: 0 3px;
}

.cheapestProviderLabel {
    color: $blueFire;
}

.cheapestPriceDivider {
    background-color: $green;
}

.cheapestPriceDivider,
.priciestPriceDivider {
    height: $dividerHeight;
    width: 5px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.priciestProviderLabel {
    color: $ersterFussballClubKoeln;
}

.priciestPriceDivider {
    background-color: $ersterFussballClubKoeln;
}

.totalSaving {
    color: $chatbotBaumGreen;
    position: absolute;
    top: $dividerHeight * 0.5;
    text-align: center;
    width: 100%;
}

.sampleCalculation {
    margin-bottom: $global-spacing;
}

.text {
    white-space: normal;
    margin-bottom: $global-spacing;
}

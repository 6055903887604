@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.priceContainer {
    display: flex;
    line-height: 1;

    &:hover {
        cursor: help;
    }

    &__smallerFontSize {
        .strikeThroughPrice {
            font-size: 12px;

            &:hover {
                font-size: 12px;
            }
        }

        .infoIcon {
            font-size: 10px;
            margin-left: $global-spacing-xsmall;
        }
    }

    &__mobile {
        align-items: baseline;
    }

    &__isUnavailable {
        &:hover {
            cursor: unset;
        }

        .strikeThroughPrice,
        .strikeThroughPrice:hover,
        .strikeThroughPrice:active,
        .strikeThroughPrice:focus,
        .infoIcon {
            color: $plainGrey;
        }
    }
}

.strikeThroughPrice {
    &,
    &:hover,
    &:active,
    &:focus {
        color: $ersterFussballClubKoeln;
        font-size: 13px;
        text-decoration: line-through;
    }
}

.infoIcon {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
    margin-left: $global-spacing-xsmall;

    &__desktop {
        color: $dustyGrey;
        margin-left: $global-spacing-small;
    }

    &__mobile {
        &.infoIcon__firstInfoIcon {
            margin-right: $global-spacing-xsmall;
        }
    }
}

@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/css-components/searchformVariables';

$componentClass: searchFormHead;

.#{$componentClass} {
    width: 100%;
    position: relative;
    align-items: center;

    &--fixedHeight {
        height: 200px;
    }

    &--es {
        max-width: unset;
        height: 245px;
    }

    &--flexDesign {
        display: flex;
        justify-content: space-between;

        $topSpace: 70px;
        $headlinePadding: 7px;

        .#{$componentClass} {
            &__bestPrice {
                margin-top: $topSpace + $headlinePadding;
            }
        }
    }

    &__headlineWrapper {
        position: relative;
        background: no-repeat right 30px;
        background-size: 450px;
        width: 100%;

        &--fixedHeight {
            height: 198px;
        }

        &--withTitleBackground {
            .#{$componentClass} {
                &__headline {
                    background-color: rgba($sophieWhite, 0.75);
                    border-radius: 4px;
                    padding: 7px 23px;
                }
            }
        }

        &--smallHeadline {
            .#{$componentClass}__title {
                font-size: 26px;
            }
        }

        &--maxWidth {
            max-width: min(740px, calc(100% - 398px));
        }
    }

    &__headline {
        display: inline-block;
        padding: 7px 0;
    }

    &__title {
        margin-bottom: 15px;

        // These values should match with other verticals for vertical start page
        font-size: 30px;
        line-height: 38px;
    }

    &__subtitle {
        font-size: 18px;
    }
}

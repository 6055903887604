@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'FrontPage/view/shared/searchForm/css-components/searchFormContainer';
@import 'FrontPage/view/shared/searchForm/css-components/searchFormHead';

$componentClass: frontPageHeader;

.frontPageHeaderWrapper {
    display: flex;
    flex-direction: column;
}

.#{$componentClass} {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 357px; // To align height with PV header height.
    padding: 0 $global-spacing $global-spacing-xlarge;
    background: no-repeat center top;

    &__content {
        position: relative;
        width: $global-width;
        margin: 0 auto;

        &--extend {
            width: 100%;
            max-width: $global-expanded-width;
            min-width: $global-width;
        }
    }

    &--cleanStyle {
        background: linear-gradient(to bottom, $sophieWhite 0%, $almostWhite 100%);
        border-bottom: 1px solid $marktMaedels;
    }

    &--isHomeTeaser {
        display: block;
        min-height: 461px;
        padding-bottom: 22px;
    }
}

.frontPageHeaderSupplierContainer {
    margin-top: 32px;
}

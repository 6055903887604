@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/mixins/lightedGrayscale';

.supplierSlider {
    max-width: $grid-row-width;

    @include breakpoint(large) {
        max-width: $global-expanded-width;
    }

    @media screen and (width <= $global-expanded-width + $global-spacing) {
        margin: 0 $global-spacing;
    }
}

.arrowSlider {
    padding: 0 10px;
}

.logoContainer {
    width: 132px;
    padding: 6px 0;
    outline: none;

    &__withMarginTop {
        margin-top: 5px;
    }
}

.logo {
    @include lightedGrayscale;

    margin: 0 auto;
    transition: filter 1s;

    &:hover {
        filter: none;
    }
}

.linkContainer {
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;

    &__withMarginTop {
        margin-top: 5px;
    }
}

.link {
    position: relative;
    top: 25%;
}

.slide {
    height: auto !important;
}

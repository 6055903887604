@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Icons/view/generated/icons';
@import 'Application/view/css-components/searchformVariables';

$wrapperPadding: $global-spacing-medium;

.headline {
    font-size: 23px;
    font-weight: bold;
    text-align: center;
    margin-bottom: $global-spacing-large;
}

.loginTeaser {
    border: 1px solid $grey;
    border-radius: $global-radius;

    &__tablet {
        .wrapper {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}

.wrapper {
    position: relative;
    max-width: $global-expanded-width;
    padding: $wrapperPadding 15px;
    margin: 0 auto;

    &__withMinWidth {
        min-width: $global-width;
    }
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title {
    font-size: 24px;
    line-height: 1;
    margin-bottom: $global-spacing;
}

.subTitle {
    display: flex;
    font-size: 16px;
    line-height: 1;
    margin-bottom: $global-spacing-medium;
    gap: $global-spacing-small;
}

.buttonContainer {
    width: 256px;
    height: 40px;
}

.buttons {
    display: flex;
    gap: $global-spacing-medium;
}

.textButton {
    font-size: 12px;
    align-self: flex-end;
}

@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';
@import 'Application/view/search-form/components/tooltips/searchFormTooltipContainer';

.manualRoomConfiguration {
    @include searchFormToolTipContainer;

    padding: 0;
    min-width: 375px;
    margin-top: 0;

    &__renderInOverlay {
        box-shadow: none;

        .paddedContainer {
            padding: 0;
        }

        .roomAddContainer {
            justify-content: flex-start;
        }
    }

    &__mobile {
        box-shadow: none;
        min-width: unset;

        .roomSetupInput {
            border-top: 1px solid $silver;
            padding-top: $global-spacing;

            &:first-child,
            &:nth-child(2) {
                border-top: none;
                padding-top: 0;
            }
        }

        .roomAddContainer {
            padding-top: $global-spacing;
        }

        .automaticViewLink {
            margin-bottom: $global-spacing;
            font-size: 16px;
        }
    }
}

.paddedContainer {
    padding: $global-spacing $global-spacing 0;

    &__withPaddingBottom {
        padding-bottom: $global-spacing;
    }

    &__ctaWithPadding {
        padding: $global-spacing;

        .roomAddContainer {
            margin-bottom: $global-spacing-small;
        }
    }
}

.roomAddContainer {
    display: flex;
    justify-content: flex-end;
}

.roomAddButton {
    padding: $global-spacing-small 0;
    font-size: 16px;
}

.roomSetupInput {
    font-size: 16px;

    &__withMarginBottom {
        margin-bottom: $global-spacing;
    }
}

.automaticViewLink {
    text-align: right;
    font-size: 14px;
}

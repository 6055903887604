@import 'Application/view/generated/colors';
@import 'Icons/view/generated/icons';
@import 'Application/view/framework/common/mixins/topDestination_mixins';
@import 'Application/view/framework/common/scssHelper/cssGridHelpers';

.location {
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    border-bottom: 1px solid $marktMaedels;
    color: $silverStar;
    cursor: pointer;
    transition: border-bottom-color 500ms;

    &:hover {
        border-bottom: 1px solid $scorpion;
        color: $juna;

        .icon {
            color: $juna;
        }
    }

    @include breakpoint(small only) {
        &:nth-child(n + 3) {
            display: none;
        }
    }

    @include breakpoint(medium only) {
        &:nth-child(n + 4) {
            display: none;
        }
    }
}

.icon {
    display: inline-flex;
    color: $plainGrey;
    font-size: 32px;
    transition: color 500ms;
}

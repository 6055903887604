@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.loyaltyTeaser {
    background-color: $sophieWhite;
    border-top: unset;
    border-bottom: unset;
}

.headline {
    text-align: center;
    margin-bottom: 20px;
    font-size: 23px;
    font-weight: bold;
    color: $silverStar;
}

.wrapper {
    padding: 0;
    max-width: $grid-row-width;

    @include breakpoint(large) {
        max-width: $global-expanded-width;
    }
}

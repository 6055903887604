@import 'Application/view/framework/common/settings';

.headline {
    font-size: 20px;
    margin: $global-spacing 10px;
}

.slider {
    margin-bottom: $global-spacing;
}

@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.effectivePriceContainer {
    display: flex;
    column-gap: $global-spacing-xsmall;
    align-items: flex-end;

    &__twoColumns {
        flex-direction: column;
        align-items: flex-end;
    }

    &__wrapPrice {
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    &__withHelpOnHover {
        &:hover {
            cursor: help;
        }
    }

    &__alignOnBaseline {
        align-items: baseline;
    }

    &__isUnavailable {
        color: $plainGrey;

        .icon {
            color: $plainGrey;
        }
    }
}

.containerWithIcon {
    display: flex;
    align-items: center;
    column-gap: $global-spacing-xsmall;
}

.icon {
    display: inline-flex;
    font-size: 12px;
    color: $dustyGrey;
}

.price {
    font-size: 24px;
    line-height: 27px;
}

@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.checkmark {
    margin-top: $global-spacing-xsmall;
    display: flex;
    gap: $global-spacing-smedium;
}

.icon {
    color: $brat;
    font-size: 13px;
    display: flex;
    align-items: center;
    height: 18px;
}

@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/mixins/frontPage_mixins';

.ekomi {
    font-size: 12px;
    margin-top: $landingpage-top-level-element-spacing;
}

.title {
    @include frontPageHeadline;

    margin-bottom: $frontpage-headlines-margin-bottom;
}

.columns {
    display: flex;
    gap: $global-spacing;

    > * {
        flex: 1;
    }
}

.widgetAndDescription {
    display: flex;
    gap: $global-spacing;

    > * {
        flex: 1;
    }
}

.subtitle {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: $global-spacing-small;
}

.description {
    margin-bottom: $global-spacing-small;
    line-height: normal;
}

.ratings {
    // Same width as AwardSlider to align content + gutter padding
    max-width: 600px;
    flex: 2;
}

.percentageBar {
    $margin: 6px;

    position: relative;
    width: 100%;
    height: 10px;
    margin-top: $margin;
    background-color: $marktMaedels;
    overflow: hidden;
    border-radius: 10px;

    &:first-of-type {
        margin-top: $margin * 0.5;
    }
}

.percentageBarFilled {
    position: absolute;
    height: 100%;
    background-color: $buckIsBluetiful;
}

.percentage {
    margin-bottom: 0;
    line-height: 16px;
}

.stars {
    display: block;
    height: 16.25px;
}

.starRanges {
    display: flex;
    gap: $global-spacing;

    > :nth-child(1) {
        flex: 0;
    }

    > :nth-child(2) {
        flex: 1;
    }

    > :nth-child(3) {
        flex: 0;
    }
}

@import 'Application/view/framework/common/settings';

.header {
    font-size: 23px;
    font-weight: bold;
    text-align: center;
    margin-bottom: $global-spacing-large;
}

.toggleMoreLink {
    display: block;
    font-size: 14px;
    margin-top: $global-spacing-medium;
}

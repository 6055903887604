@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'FrontPage/view/frontPage/components/travel-community/variables';
@import 'Results/view/results/components/tile/mixins/noLinkStyles';

.tile {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 252px;
    border-radius: 10px;
    color: $silverStar;
    text-decoration: none;
    cursor: pointer;
    box-shadow:
        6px 6px 6px rgba($darkSideOfTheForce, 0.01),
        3px 3px 5px rgba($darkSideOfTheForce, 0.03),
        5px 3px 6px rgba($darkSideOfTheForce, 0.05);

    @include noLinkStyles;

    &:hover {
        color: $silverStar;
        box-shadow:
            rgba($darkSideOfTheForce, 0) 0 0 0 0,
            rgba($darkSideOfTheForce, 0) 0 0 0 0,
            rgba($darkSideOfTheForce, 0.26) 0 1px 3px 0;

        .header {
            color: $juna;
        }
    }

    &:active {
        color: $silverStar;
    }

    @media screen and (max-width: $global-width) {
        height: 275px;

        .image {
            height: 182px;
        }

        .iconContainer {
            top: calc(182px - 30px);
        }
    }
}

.image {
    height: 159px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.content {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: $global-spacing-smedium;
    padding: 0 $global-spacing-medium;
}

.header {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.subLine {
    font-size: 14px;
    line-height: 17px;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc(159px - 30px);
    left: $global-spacing-medium;
    width: 40px;
    height: 40px;
    background: $sophieWhite;
    border-radius: 50%;
    border: 1px solid $marktMaedels;
}

.icon {
    display: inline-flex;
    font-size: 34px;

    &__cycling {
        font-size: 32px;
    }

    &__diving {
        font-size: 36px;
    }

    & > span {
        // Is required to invert icons
        fill: none;
        stroke-width: 10px;
    }
}

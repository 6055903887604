@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.topicTab {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: $global-spacing-small;
    padding: $global-spacing-small $global-spacing;
    border-bottom: 2px solid $transparent;

    &__isSelected {
        color: $blueFire;
        border-bottom-color: $blueFire;
    }

    &:hover {
        cursor: pointer;
        background: $frosteon;
    }

    &__asChip {
        border: 1px solid $marktMaedels;
        border-radius: 20px;
        padding: 6px 10px;
        margin-right: 10px;

        &.topicTab__isSelected {
            color: unset;
            background-color: rgba($blueFire, 0.1);
            border-color: $blueFire;
        }
    }

    &__biggerFontsize,
    &__mobile {
        .label {
            font-size: 16px;
        }
    }
}

.label {
    font-size: 14px;
    line-height: 15px;
}

.icon {
    display: inline-flex;
    font-size: 20px;
}

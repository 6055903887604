@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/mixins';
@import 'Application/view/framework/common/settings';

.headline {
    text-align: center;
    font-weight: bold;
    font-size: 23px;
    margin-bottom: $global-spacing-xlarge;

    &__lessMargin {
        margin-bottom: $startpage-attack-headlines-margin-bottom;
    }
}

.tiles {
    display: flex;
    flex-direction: row;
    column-gap: $global-spacing-small;
}

@import 'Application/view/framework/common/settings';

.container {
    max-width: 180px;
    padding: $global-spacing-small;
    font-size: 12px;

    &__tablet {
        padding: $global-spacing; // To compensate close icon dimensions.
    }
}

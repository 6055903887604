@import 'Application/view/generated/colors';
@import 'Application/view/generated/assets/assets';

.plectrum {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 120px;
    height: 100px;
    color: $sophieWhite;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url($plectrumSvg);

    &__es {
        width: 145px;
        height: 110px;

        .text {
            font-size: 16px;
        }

        .discount {
            font-size: 30px;
        }
    }
}

.text {
    line-height: 1;
    font-size: 16px;
}

.discount {
    font-size: 30px;
    font-weight: bold;
}

.infoIcon {
    font-size: 11px;
    padding-left: 5px;
}
